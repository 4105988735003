import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { selectGameId, selectRoleJoinId, setJoinRole } from '../../../redux/Slices/JoinGameSlice';
import { SelectRole, selectRoleId, setRole, UpdateRole } from '../../../redux/Slices/SelectRoleSlice';
import { getLobby, selectAllLobby } from '../../../redux/Slices/lobbySlice';
import { getAvatars, selectAvatars, selectAvatarsError, selectAvatarsStatus } from '../../../redux/Slices/AvailableAvatars';
import Loader from '../../atoms/Loader/Loader';
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';
import { selectGetUser } from '../../../redux/Slices/authSlice';
import { getProfile } from '../../../redux/Slices/ProfileSlice';
import './ChooseAvatars.scss';
import BackButton from '../../atoms/BackButton/BackButton';

const ChooseAvatars = () => {
    const [loading, setLoading] = useState(false);
    const [selectedAvatar, setSelectedAvatar] = useState<number | undefined>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch: AppDispatch = useDispatch();
    const avatars = useSelector(selectAvatars);
    const avatarStatus = useSelector(selectAvatarsStatus);
    const avatarError = useSelector(selectAvatarsError)
    const gameId = useSelector(selectGameId)
    const roleId = useSelector(selectRoleId)
    const joinRoleId = useSelector(selectRoleJoinId);
    const profile = useSelector(selectGetUser);
    const lobby = useSelector(selectAllLobby);
    const location = useLocation();
    const navigate = useNavigate();

    const { role } = location.state || {};
    const { update } = useParams();

    useEffect(() => {
        dispatch(getAvatars(gameId)); // Fetch avatars based on game ID
    }, [dispatch, gameId]);

    useEffect(() => {
        if (avatarStatus === 'loading') {
            setLoading(true);
        } else if (avatarStatus === 'succeeded') {
            setLoading(false);
        } else if (avatarStatus === 'failed') {
            setLoading(false);
            if (avatarError?.error?.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [avatarStatus]);

    const shouldShowButtons = () => {
        const role = joinRoleId !== undefined ? joinRoleId : roleId;  // Use roleId if joinRoleId is undefined
        return role === 2;
    };

    const handleAvatarSelect = (avatarId: number) => {
        setSelectedAvatar(avatarId);
        setLoading(true);
        // Dispatch SelectRole with both roleId and avatarId

        if (update === 'false') {
            dispatch(SelectRole({ role: role, avatar_id: avatarId, gameId }))
                .then((action) => {
                    const { success, error } = action.payload
                    if (success) {
                        setLoading(false);
                        message.success(action.payload.message);
                        // Now, refetch the lobby to get the updated game_status
                        dispatch(getLobby(gameId)).unwrap().then((lobbyData: any) => {
                            const gameStatus = lobbyData?.game_status;
                            if (gameStatus === 1) {
                                navigate('/showdown-lobby');
                            } else if (gameStatus === 2) {
                                navigate('/stock-prices');
                            } else {
                                navigate('/showdown-lobby');
                            }
                        }).catch((error) => {
                            message.error('Failed to fetch game status. Please try again.');
                        });
                        navigate('/showdown-lobby')
                    } else if (error) {
                        const errorMessage = error.message;
                        if (error.message === "User already in this board") {
                            if (shouldShowButtons()) {
                                navigate('/stock-prices');
                            } else {
                                navigate('/showdown-lobby');
                            }
                        } else {
                            setErrorMessage(errorMessage);
                        }
                        setErrorMessage(errorMessage);
                        message.error(errorMessage);
                        setLoading(false);
                    }
                }).catch((error) => {
                    setLoading(false);
                    message.error('Something went wrong. Please try again.');
                });
        } else {
            let payload: any = {
                role: role, avatar_id: avatarId, gameId,
            }
            if (lobby && lobby.game_players_data.length === 7) {
                const lb = lobby.game_players_data.filter((lb: any) => lb.avatar_id === avatarId);
                if (lb.length > 0) {
                    payload.player_id = lb[0].player_id;
                    payload.role = 2
                }
            }
            dispatch(UpdateRole(payload)).then((action) => {
                const { success, error } = action.payload
                if (success) {
                    setLoading(false)
                    message.success(action.payload.message);
                    if (lobby && lobby.game_players_data.length !== 7) {
                        dispatch(getProfile());
                    }
                    handleGetLobby();
                    console.log('success', success)
                } else if (error) {
                    const errorMessage = error.message;
                    console.log('errorMessage', errorMessage)
                }
            }).catch((error) => {
                setLoading(false);
                message.error('Something went wrong. Please try again.');
            });
        }
    };

    const handleSwapRole = (lb: any) => {
        let payload: any = {
            role: lb.role, player_id: lb.player_id, gameId
        }
        dispatch(UpdateRole(payload)).then((action) => {
            const { success, error } = action.payload
            if (success) {
                setLoading(false)
                message.success(action.payload.message);
                // role update;
                dispatch(setRole({ role: payload.role }))

                dispatch(setJoinRole({ roleId: payload.role }))
                handleGetLobby();
            } else if (error) {
                const errorMessage = error.message;
                message.error(errorMessage);
            }
        }).catch((error) => {
            setLoading(false);
            message.error('Something went wrong. Please try again.');
        });
    }

    const handleGetLobby = () => {
        dispatch(getLobby(gameId)).unwrap().then((lobbyData: any) => {
            const gameStatus = lobbyData?.game_status;
            if (gameStatus === 1) {
                navigate('/showdown-lobby');
            } else if (gameStatus === 2) {
                navigate('/stock-prices');
            } else {
                navigate('/showdown-lobby')
            }
        }).catch((error) => {
            console.error('Failed to fetch lobby:', error);
            message.error('Failed to fetch game status. Please try again.');
        });
        navigate('/showdown-lobby')
    }
    // Handle Refresh Button to reload portfolio value data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getAvatars(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const handleBackButton = () => {
        navigate(-1)
    }

    return (
        <div>
            <div className='account-heading'>
                <BackButton onClick={handleBackButton} back_class='back-choose-avatar' />
            </div>
            <RefreshButton onClick={handleRefreshButton} />
            {/* <div className='account-heading'>
            </div> */}
            <div className='flex login-container join-avatar' style={{ justifyContent: "center" }}>
                <div className='main-heading font-bagel response-heading' >Choose your<br /> Avatar
                    {/* <span className="get-help-addOn-hover" >
                        <span className="help-image-logo" > <IoMdInformationCircleOutline size={20} cursor='pointer' style={{ marginBottom: "-5px" }} color='#FFC107' /></span>
                        <span className="get-help-code-hover">Choose your role: Investor, Broker, or both. At least one player must be a Broker to update stock prices</span>
                    </span> */}
                </div>
                <div className='flex login-upper-container ' style={{ paddingBottom: "30px" }}>
                    <div className='border-blue'>
                        <div className='border-dark-blue'>
                            <div className='border-orange'>
                                <div className='border-yellow'>
                                    <div className='border-inner-blue'>
                                        <div className='joinGame-dimension flex'>
                                            {!loading ?
                                                <div className='joinGame-container flex' style={{ width: "75%" }}>
                                                    {/* <div className='note-response'>At least one player must be a Broker to continue <span className="get-help-addOn-hover" >
                                                        <span className="help-image-logo" > <IoMdInformationCircleOutline size={16} cursor='pointer' style={{ marginBottom: "-5px" }} color='#FFC107' /></span>
                                                        <span className="get-help-response-hover" style={{ height: "42px" }}>Choose your role: Investor, Broker, or both. At least one player must be a Broker to update stock prices</span>
                                                    </span>
                                                    </div> */}
                                                    <div className='chooseAvatars' >
                                                        {(update === "true" && lobby && lobby.game_players_data.length === 7) ? lobby?.game_players_data?.map((lobbyAvatar: any) => {
                                                            return (
                                                                lobbyAvatar.avatar_id !== 7 && <div key={lobbyAvatar.player_id} onClick={() => lobbyAvatar.player_id !== undefined && handleSwapRole(lobbyAvatar)}>
                                                                    <img src={lobbyAvatar.image} alt={lobbyAvatar.name} className='avatar-image' draggable="false"
                                                                        onContextMenu={(e) => e.preventDefault()} />
                                                                </div>
                                                            )
                                                        }) :
                                                            <>
                                                                {avatars?.data?.map((avatar: any) => {
                                                                    return (
                                                                        <>
                                                                            <div key={avatar.id} onClick={() => avatar.id !== undefined && handleAvatarSelect(avatar.id)}>
                                                                                <img src={avatar.image} alt={avatar.name} className='avatar-image' draggable="false"
                                                                                    onContextMenu={(e) => e.preventDefault()} />
                                                                            </div>

                                                                        </>
                                                                    )
                                                                })}
                                                                {
                                                                    update === "true" && lobby &&
                                                                    <div key={lobby.game_players_data.filter((data: any) => data.user_id === profile.id)[0].user_id} onClick={() => handleGetLobby()}>
                                                                        {lobby.game_players_data.filter((data: any) => data.user_id === profile.id)[0] .avatar_id !== 7 && <img src={lobby.game_players_data.filter((data: any) => data.user_id === profile.id)[0].image} alt={lobby.game_players_data.filter((data: any) => data.user_id === profile.id)[0].username} className='avatar-image' draggable="false"
                                                                            onContextMenu={(e) => e.preventDefault()} />}
                                                                    </div>
                                                                }
                                                            </>
                                                        }

                                                    </div>
                                                </div> : <Loader height='280px' />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseAvatars;