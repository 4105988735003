import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import { image } from '../../../app/utils/common/image';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { selectBrokerExists, selectGameId, selectRoleJoinId } from '../../../redux/Slices/JoinGameSlice';
import { SelectRole, selectRoleId, UpdateRole } from '../../../redux/Slices/SelectRoleSlice';
import { getLobby } from '../../../redux/Slices/lobbySlice';
import Loader from '../../atoms/Loader/Loader';
import Button from '../../atoms/Button/Button';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';
import './JoinGameScreen.scss';
import BackButton from '../../atoms/BackButton/BackButton';

const JoinGameScreen = () => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loadingRole, setLoadingRole] = useState<boolean>(false)
    const [selectedRole, setSelectedRole] = useState<number | null>(null);
    const { update } = useParams();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const gameId = useSelector(selectGameId)
    const roleId = useSelector(selectRoleId)
    const joinRoleId = useSelector(selectRoleJoinId);
    const brokerExists = useSelector(selectBrokerExists);

    const handleBackButton = () => {
        navigate(-1)
    };

    const shouldShowButtons = () => {
        const role = joinRoleId !== undefined ? joinRoleId : roleId;  // Use roleId if joinRoleId is undefined
        return role === 2;
    };

    // Function to handle joining the game with the selected role
    const handleJoinGame = (role: number) => {
        setSelectedRole(role);
        setLoadingRole(true);
        if (role === 1) {
            // Dispatch SelectRole without avatar selection and navigate based on game status
            if (update === 'false') {
                dispatch(SelectRole({ role, gameId }))
                    .then((action: any) => {
                        const { success, error } = action.payload;
                        if (success) {
                            // Refetch the lobby to get the updated game_status
                            dispatch(getLobby(gameId)).unwrap().then((lobbyData: any) => {
                                const gameStatus = lobbyData?.game_status;
                                if (gameStatus === 1) {
                                    navigate('/showdown-lobby');
                                } else if (gameStatus === 2) {
                                    navigate('/stock-prices');
                                } else {
                                    navigate('/showdown-lobby');
                                }
                            }).catch((error) => {
                                console.error('Failed to fetch lobby:', error);
                                message.error('Failed to fetch game status. Please try again.');
                            });
                        } else if (error) {
                            const errorMessage = error?.message;
                            if (error.message === "User already in this board") {
                                if (shouldShowButtons()) {
                                    navigate('/stock-prices');
                                } else {
                                    navigate('/showdown-lobby');
                                }
                            } else {
                                setErrorMessage(errorMessage);
                            }
                            console.log('errorMessage', errorMessage)
                            message.error(errorMessage);
                            setLoadingRole(false);
                        }
                    })
                    .catch((error) => {
                        console.error('Failed to dispatch SelectRole:', error);
                        setLoadingRole(false);
                        message.error('Something went wrong. Please try again.');
                    });
            } else {
                dispatch(UpdateRole({ role: role, gameId })).then((action) => {
                    const { success, error } = action.payload
                    if (success) {
                        setLoadingRole(false)
                        message.success(action.payload.message);
                        dispatch(getLobby(gameId)).unwrap().then((lobbyData: any) => {
                            const gameStatus = lobbyData?.game_status;
                            if (gameStatus === 1) {
                                navigate('/showdown-lobby');
                            } else if (gameStatus === 2) {
                                navigate('/stock-prices');
                            } else {
                                navigate('/showdown-lobby')
                            }
                        }).catch((error) => {
                            console.error('Failed to fetch lobby:', error);
                            message.error('Failed to fetch game status. Please try again.');
                        });
                        navigate('/showdown-lobby')
                        console.log('success', success)
                    } else if (error) {
                        setLoadingRole(false);
                        const errorMessage = error.message;
                        message.error(errorMessage)
                        console.log('errorMessage', errorMessage)
                    }
                }).catch((error) => {
                    console.log('error in update', error)
                    setLoadingRole(false);
                    message.error('Something went wrong. Please try again.');
                });
            }
        } else if (role === 3) {
            // For other roles, proceed with the existing logic
            if (brokerExists) {
                message.error('Broker Role already filled');
                setLoadingRole(false);
            } else {
                navigate(`/choose-avatar/${update}`, { state: { role: role } });
            }
        } else {
            navigate(`/choose-avatar/${update}`, { state: { role: role } });
        }
    };

    return (
        <div>
            <div className='account-heading'>
                <BackButton onClick={handleBackButton} back_class='back-choose-avatar'/>
            </div>
            <div className='flex login-container' style={{ height: "100vh" }}>
                <div className='main-heading font-bagel response-heading' >Choose your<br /> role
                    {/* <span className="get-help-addOn-hover" >
                        <span className="help-image-logo" > <IoMdInformationCircleOutline size={20} cursor='pointer' style={{ marginBottom: "-5px" }} color='#FFC107' /></span>
                        <span className="get-help-code-hover">Choose your role: Investor, Broker, or both. At least one player must be a Broker to update stock prices</span>
                    </span> */}
                </div>
                <div className='flex login-upper-container ' style={{ paddingBottom: "30px" }}>
                    <div className='border-blue'>
                        <div className='border-dark-blue'>
                            <div className='border-orange'>
                                <div className='border-yellow'>
                                    <div className='border-inner-blue'>
                                        <div className='joinGame-dimension flex'>
                                            {!loadingRole ? <div className='joinGame-container flex'>
                                                <div className='note-response'>At least one player must be a Broker to continue <span className="get-help-addOn-hover" >
                                                    <span className="help-image-logo" > <IoMdInformationCircleOutline size={16} cursor='pointer' style={{ marginBottom: "-5px" }} color='#FFC107' /></span>
                                                    <span className="get-help-response-hover" style={{ height: "42px" }}>In the game, there must be exactly one Broker; no more, no less</span>
                                                </span></div>
                                                <div style={{ position: "relative" }} onClick={() => { handleJoinGame(2); sendFirebaseEvent('investor_button', 'user_action', 'investor_button', "I'm Investor Button Clicked") }}>
                                                    {/* <div className='flex'>
                                                        <img src={image.INVESTORAVATAR} className='investor-image' />
                                                    </div> */}
                                                    <div>
                                                        <Button buttonTitle="I'm Investor" onClick={() => { sendFirebaseEvent('join_lobby_button', 'user_action', 'join_lobby', 'Join Lobby Button Clicked') }} width={'200px'} />
                                                        {selectedRole === 2 && <img src={image.RIGHTTICK} className='selc-profile' draggable="false"
                                                            onContextMenu={(e) => e.preventDefault()} />}
                                                    </div>
                                                </div>
                                                <div style={{ position: "relative" }} onClick={() => { handleJoinGame(1); sendFirebaseEvent('broker_button', 'user_action', 'broker_button', "I'm Broker Button Clicked") }}>
                                                    {/* <div className='flex'>
                                                        <img src={image.BROKERAVATAR} className='investor-image' />
                                                    </div> */}
                                                    <div>
                                                        <Button buttonTitle="I'm Broker" onClick={() => { }} width={'200px'} />
                                                        {selectedRole === 1 && <img src={image.RIGHTTICK} className='selc-profile' draggable="false"
                                                            onContextMenu={(e) => e.preventDefault()} />}
                                                    </div>
                                                </div>
                                                <div style={{ position: "relative" }} onClick={() => { handleJoinGame(3); sendFirebaseEvent('Inves_broker_button', 'user_action', 'inves_broker_button', "I'm Investor & Broker Button Clicked") }}>
                                                    {/* <div className='flex'>
                                                        <img src={image.BROKERINVESTAVATAR} className='investor-image' />
                                                    </div> */}
                                                    <div>
                                                        <Button buttonTitle="I'm Investor & Broker" onClick={() => { }} width={'200px'} />
                                                        {selectedRole === 3 && <img src={image.RIGHTTICK} className='selc-profile' draggable="false"
                                                            onContextMenu={(e) => e.preventDefault()} />}
                                                    </div>
                                                </div>
                                            </div> : <Loader height='280px' />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinGameScreen;