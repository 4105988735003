import React, { useState } from 'react'
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserData, register, selectAuthStatus, sendOtp, verifyOtp } from '../../../redux/Slices/authSlice';
import { AppDispatch } from '../../../redux/store';
import LOGINUPPERSIDE from '../../../assets/images/login.svg';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import Loader from '../../atoms/Loader/Loader';
import VerifyLoginOtp from '../VerifyLoginOtp/VerifyLoginOtp';
import './LoginForm.scss'
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';

const LoginForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('+91');
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeButton, setActiveButton] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [registerModal, setRegisterModal] = useState<boolean>(false);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state: any) => state.auth);
  const authStatus = useSelector(selectAuthStatus);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      isOtpSent ? handleRegister() : handleSendOtp();
    }
  };
  // Handle to send otp
  const handleSendOtp = () => {
    setLoading(true);
    setErrorMessage('');
    sendFirebaseEvent('otp_button', 'user_action', 'sendOtp', 'Get OTP Button Clicked');
    dispatch(sendOtp({ phoneNumber, countryCode })).then((action: any) => {
      // Dispatch the send OTP action
      setLoading(false);
      if (action.type === 'auth/sendOtp/fulfilled') {
        setShowOtpModal(true);
        setErrorMessage('');
        setIsOtpSent(true);
      } else if (action.type === 'auth/sendOtp/rejected') {
        setErrorMessage(action.payload?.error?.message);
      }
    });
  };

  // Handle to verify otp
  const handleVerifyOtp = () => {
    const otpString = otp.join('');
    // Ensure that OTP is entered before verification
    if (!otpString) {
      setErrorMessage('Please enter the OTP');
      return;
    }
    setLoading(true);
    setErrorMessage('');
    sendFirebaseEvent('verify_otp_button', 'user_action', 'verifyOtp', 'Verify Button Clicked');
    dispatch(verifyOtp({ countryCode, phoneNumber, otpId: authState.otpId, otp: otpString }))
      .then((action: any) => {
        setLoading(false);
        const { success, error } = action.payload;
        if (success) {
          const { data } = action.payload;
          localStorage.setItem('access_token', data?.token_details.access_token);
          localStorage.setItem('refresh_token', data?.token_details.refresh_token);
          setErrorMessage('');
          navigate('/profile'); // Navigate to profile on successful OTP verification
        } else if (error) {
          const Error = error.message || 'Failed to verify OTP';
          if (error.message === "The entered OTP is incorrect. Please try again.") {
            setErrorMessage(Error);
          } else if (error.message === "No user found with this phone number.") {
            setErrorMessage(`${Error}Please register first`);
            setShowOtpModal(false)
            setRegisterModal(true)
          }

        }
      })
      .catch((err: any) => {
        setLoading(false);
        setErrorMessage('Error verifying OTP: ' + err.message);
      });
  };


  const handleRegister = () => {
    if (!userName || !phoneNumber) {
      setErrorMessage('Please enter your name and phone number.');
      return;
    }

    setLoading(true);
    setErrorMessage(''); // Clear any previous error message
    dispatch(clearUserData());
    dispatch(register({ username: userName, countryCode, phoneNumber }))
      .then((action: any) => {
        setLoading(false);
        const { success, error } = action.payload;
        if (success) {
          setErrorMessage('');
          const { data } = action.payload;
          localStorage.setItem('access_token', data?.token_details.access_token);
          localStorage.setItem('refresh_token', data?.token_details.refresh_token);
          setShowOtpModal(true);
          navigate('/profile')
        } else if (error) {
          const Error = error.message || 'Failed to register';
          setErrorMessage(Error);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setErrorMessage('Error during registration: ' + err.message);
      });
  };

  const handlePhoneChange = (event: any) => {
    if (event.target.value.length <= 10) {
      setPhoneNumber(event.target.value);
      setActiveButton(event.target.value.length === 10);
      setErrorMessage('')
    }
  };

  const handleUserNameChange = (event: any) => {
    setUserName(event.target.value);
    setErrorMessage('')
  };

  const handleOtpChange = (e: any, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    setErrorMessage('')
  };

  const handleBackButton = () => {
    navigate(-1)
  }

  return (
    <div className=''>
      {/* {showOtpModal && <BackButton onClick={handleBackButton} />} */}
      <div className='flex login-container' onKeyDown={handleKeyDown} // Attach keydown listener
        tabIndex={0}>
        <img src={window.innerWidth > 750 ? image.WEBLOGO : image.LOGOBGCOLOR} className='login-bg-img '
          draggable="false"
          onContextMenu={(e) => e.preventDefault()} />
        <div className='flex login-upper-container'>
          <img src={LOGINUPPERSIDE} className='login-upper' draggable="false"
            onContextMenu={(e) => e.preventDefault()} />
          {!showOtpModal ? (
            registerModal ? (
              <div className="register-text ">Register</div>
            ) : (
              <img src={image.LOGINTEXT} className="login-text" draggable="false"
                onContextMenu={(e) => e.preventDefault()} />
            )
          ) : (
            <img src={image.VERIFICATIONTEXT} className="login-text" draggable="false"
              onContextMenu={(e) => e.preventDefault()} />
          )}
          <div className='border-blue'>
            <div className='border-dark-blue'>
              <div className='border-orange'>
                <div className='border-yellow'>
                  <div className='border-inner-blue'>
                    <div className={`${!registerModal ? 'login-dimension' : 'register-dimension'}`}>
                      {!loading ?
                        (!showOtpModal && (<div className='Phone-number-container'>
                          <div>
                            {registerModal && <div style={{ marginBottom: "12px" }}>
                              <Input
                                label='Name'
                                placeholder='Enter your alias'
                                type="text"
                                id="username"
                                name="username"
                                value={userName}
                                onChange={handleUserNameChange}
                              />
                            </div>}
                            <Input
                              label='Phone Number'
                              placeholder='Enter phone number'
                              type="tel"
                              id="tel"
                              name="phone-no"
                              value={phoneNumber}
                              onChange={handlePhoneChange}
                              info='Enter your phone number to get started'
                              ShowInfo={!registerModal ? true : false}
                              classNameInfo="get-help-add-on-hover"
                              disabled={registerModal ? true : false} />
                            <div>
                              {errorMessage && <div className='error-message'>{registerModal ? "" : errorMessage}</div>}
                            </div>
                          </div>
                          <div>
                            <div>
                              {!registerModal ? <Button onClick={handleSendOtp} buttonTitle="Get OTP" /> :
                                <Button onClick={handleRegister} buttonTitle="Sign up" />}
                            </div>
                            {!registerModal && <div className='text-footer-login'>We’ll send you a one-time password to verify your identity</div>}
                          </div>
                        </div>))
                        : (<div className="flex items-center justify-center">
                          <Loader height={'320px'} />
                        </div>
                        )}


                      {showOtpModal && <VerifyLoginOtp
                        handleOtpChange={handleOtpChange}
                        otp={otp}
                        handleVerifyOtp={handleVerifyOtp}
                        setActiveButton={setActiveButton}
                        otpId={authState.otpId}
                        setOtp={setOtp}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        loading={loading}
                        setLoading={setLoading} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginForm