export const image = {
    COLORLOGO: require('../../../assets/images/color-logo.png'),
    LOGO: require('../../../assets/images/logo.png'),
    LOGINBG: require('../../../assets/images/login-bg-1.png'),
    LOGOBGCOLOR: require('../../../assets/images/logo-bg.png'),
    LOGINTEXT: require('../../../assets/images/Login.png'),
    VERIFICATIONTEXT: require('../../../assets/images/Verification.png'),
    PROFILETEXT: require('../../../assets/images/Profile.png'),
    INVESTORAVATAR: require('../../../assets/images/Investor-avatar1.png'),
    BROKERAVATAR: require('../../../assets/images/Broker-avatar.png'),
    BROKERINVESTAVATAR: require('../../../assets/images/Broker-investor.png'),
    BACKBUTTON: require('../../../assets/images/Back-button.png'),
    SPINBUTTON: require('../../../assets/images/spin-button.png'),
    SPINNER2: require('../../../assets/images/spinner_images/2.png'),
    SPINNER3: require('../../../assets/images/spinner_images/3.png'),
    SPINNER4: require('../../../assets/images/spinner_images/4.png'),
    SPINNER5: require('../../../assets/images/spinner_images/5.png'),
    SPINNER6: require('../../../assets/images/spinner_images/6.png'),
    SPINSTAND: require('../../../assets/images/spinstand.png'),
    SPINTOP: require('../../../assets/images/spintop.png'),
    HOMEBG: require('../../../assets/images/Homebackground.png'),
    STOCKTABBTN: require('../../../assets/images/stock-tab-btn.png'),
    STOCKSELCBTN: require('../../../assets/images/stock-selc-btn.png'),
    TRADETABBTN: require('../../../assets/images/trade-tab-btn.png'),
    TRADESELCBTN: require('../../../assets/images/trade-selc-btn.png'),
    TRANSCTABBTN: require('../../../assets/images/transaction.png'),
    TRANSCSELCBTN: require('../../../assets/images/TransactionSelcBtn.png'),
    PORTFOLIOTABBTN: require('../../../assets/images/portfolio-tab-btn.png'),
    PORTFOLIOSELCBTN: require('../../../assets/images/portfolio-selc-btn.png'),
    RIGHTTICK: require('../../../assets/images/RightTick.png'),
    RIGHTARROW: require('../../../assets/images/Rightarrow.png'),
    GREENARROW: require('../../../assets/images/GreenArrow.png'),
    REDARROW: require('../../../assets/images/RedArrow.png'),
    WEBBG: require('../../../assets/images/WEB-BG.png'),
    WEBLOGINBG: require('../../../assets/images/Web-login-BG.png'),
    WEBLOGO: require('../../../assets/images/Web-logo.png'),
    TRENDUPARROW: require('../../../assets/images/trend-up-arrow.png'),
    TRENDDOWNARROW: require('../../../assets/images/trend-down-arrow.png'),
    LOGO4: require('../../../assets/images/Logo-04.jpg'),
    INSTAGRAM: require('../../../assets/images/social-logos/instagram.png'),
    LINKEDIN: require('../../../assets/images/social-logos/linked-in.png'),
    DISCORD: require('../../../assets/images/social-logos/discord.png'),
    X: require('../../../assets/images/social-logos/x.png'),
    YOUTUBE: require('../../../assets/images/social-logos/youtube.png'),
    SHOWDOWNWHITE: require('../../../assets/images/TheShowdownWhite.png'),
    SHOWDOWNBLACK: require('../../../assets/images/TheShowdownBlack.png'),
    LOGOUT: require('../../../assets/images/logout-blue.png'),
    CROSSBTN: require('../../../assets/images/remove.png')
}