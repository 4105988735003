import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTransaction, selectAllTransc, selectTranscError, selectTranscStatus } from '../../../redux/Slices/TransactionSlice'
import { selectGameId } from '../../../redux/Slices/JoinGameSlice'
import RefreshButton from '../../atoms/RefreshButton/RefreshButton'
import BottomSheet from '../../molecules/BottomSheet/BottomSheet'
import TransactionContainer from '../../molecules/TransactionContainer/TransactionContainer';
import OutsideClickHandler from 'react-outside-click-handler'
import HelpContainer from '../../molecules/HelpContainer/HelpContainer'
import { TbHelpOctagonFilled } from "react-icons/tb";
import { IoMdHelpCircleOutline } from "react-icons/io";
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent'

interface TransactionScreenProps {
    secondsLeft: number | null,
    helpShow: boolean,
    setHelpShow: React.Dispatch<React.SetStateAction<boolean>>,
    handleClose: VoidFunction,
}


const TransactionScreen: FC<TransactionScreenProps> = (props) => {
    const { secondsLeft, helpShow, setHelpShow, handleClose } = props
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const transaction = useSelector(selectAllTransc);
    const transactionStatus = useSelector(selectTranscStatus);
    const transactionError = useSelector(selectTranscError);
    const gameId = useSelector(selectGameId);

    useEffect(() => {
        dispatch(getAllTransaction(gameId)); // Dispatch Get stockPrices action
    }, [dispatch])

    // Stock Prices status loading state
    useEffect(() => {
        if (transactionStatus === 'loading') {
            setLoading(true);
        } else if (transactionStatus === 'succeeded') {
            setLoading(false);
        } else if (transactionStatus === 'failed') {
            setLoading(false);
            if (transactionError?.error?.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [transactionStatus]);

    const handleBackButton = () => {
        navigate(-1)
    }

    // Handle Refresh Button to reload transc data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getAllTransaction(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    return (
        <div className='padding-bottom'>
            <div className='account-heading'>
                {/* <BackButton onClick={handleBackButton} buttonHeading='Transaction History' className='colorHeading-bckbtn transc-back font-bagel' /> */}
                <RefreshButton onClick={handleRefreshButton} />
            </div>

            <div className='timer-heading'>
                <div className='colorHeading-bckbtn font-bagel portfolio-heading'>Trade History </div>
                {secondsLeft && secondsLeft > 0 && <div className='seconds-left'>{secondsLeft} Sec</div>}
            </div>
            {/* <div className='colorHeading-bckbtn font-bagel'>Transaction History</div> */}
            <div className='flex stocks-container'>
                <div className='flex stockContainer' style={{ gap: "20px", position: "relative" }}>
                    <div className='help-btn transaction-help-btn' style={{}} onClick={() => { setHelpShow(!helpShow); sendFirebaseEvent('help_button', 'user_action', 'help_button', "Help Button Clicked") }}>
                        <IoMdHelpCircleOutline color='#fff' size={28} cursor={'pointer'} />
                    </div>
                    {helpShow && <OutsideClickHandler onOutsideClick={handleClose}>
                        <HelpContainer setHelpShow={setHelpShow} />
                    </OutsideClickHandler>}
                    <div className='account-border'>
                        <div className='account-container flex transc-container'>
                            {transaction?.length !== 0 ?
                                <TransactionContainer transaction={transaction} loading={loading} /> :
                                <div style={{ height: "585px" }} className='empty-container'>Oops! No transaction found</div>}
                        </div>
                    </div>
                </div>
                {/* <BottomSheet /> */}
            </div>
        </div>
    )
}

export default TransactionScreen