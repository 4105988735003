import React, { useEffect, useState } from 'react';
import LOGINUPPERSIDE from '../../../assets/images/login.svg'
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import './ProfileScreen.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, selectAllProfile, selectProfileStatus } from '../../../redux/Slices/ProfileSlice';
import { AppDispatch } from '../../../redux/store';
import Loader from '../../atoms/Loader/Loader';
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';
import LogOut from '../../atoms/LogOut/LogOut';

const ProfileScreen = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const profile = useSelector(selectAllProfile);
    const dispatch: AppDispatch = useDispatch();
    const profileStatus = useSelector(selectProfileStatus);

    useEffect(() => {
        dispatch(getProfile()); // Dispatch Get Profile action
    }, []);

    // Profile status loading state
    useEffect(() => {
        if (profileStatus === 'loading') {
            setLoading(true);
        } else if (profileStatus === 'succeeded') {
            setLoading(false);
        }
    }, [profileStatus]);

    return (
        <div style={{ width: "100%" }}>
            {/* <BackButton onClick={handleBackButton} /> */}
            <LogOut />
            <div className='flex login-container' style={{ height: "100vh", overflowY: "hidden" }}>
                <img src={window.innerWidth > 750 ? image.WEBLOGO : image.LOGOBGCOLOR} className='login-bg-profile' draggable="false"
                    onContextMenu={(e) => e.preventDefault()} />
                {!loading ? <div>
                    <div className='flex profile-flex' onClick={() => { navigate('/account-details') }}>
                        <img src={profile?.profile_image || image.INVESTORAVATAR} className='investor-profile' draggable="false"
                            onContextMenu={(e) => e.preventDefault()} />
                        <div className='profile-heading'>
                            <div className='profile-heading-welcome'>Welcome</div>
                            <div style={{ fontSize: '27px' }}>{` ${profile?.username}!`}</div>
                        </div>
                    </div>
                    <div className='flex buttons-profile'>
                        <Button buttonTitle="Play 'The Showdown'" onClick={() => { navigate('/landing'); sendFirebaseEvent('play_showdown_button', 'user_action', 'the_showdown', 'Play Showdown Button Clicked') }} className={'profile-width-button'} />
                        <Button buttonTitle='Shop Now' onClick={() => { window.open('https://joyjuncture.com',"__blank"); sendFirebaseEvent('shop_now_button', 'user_action', 'shop_now', 'Play Showdown Button Clicked') }} className={'profile-width-button'} />
                    </div></div> : <Loader height={'auto'} />}
                {/* <div className='flex login-upper-container'>
                    <img src={LOGINUPPERSIDE} className='login-upper' />
                    <img src={image.PROFILETEXT} className='login-text' />
                    <div className='border-blue'>
                        <div className='border-dark-blue'> ̰
                            <div className='border-orange'>
                                <div className='border-yellow'>
                                    <div className='border-inner-blue'>
                                        <div className='profile-dimension'>
                                            <div className='profile-container flex'>
                                                <img src={image.INVESTORAVATAR} className='investor-profile' />
                                                <div className='profile-btn-container flex'>
                                                    <Button buttonTitle='Account Information' onClick={() => { navigate('/account-details') }} className={'profile-width-button'} />
                                                    <Button buttonTitle="Play 'The Showdown'" onClick={() => { navigate('/showDown') }} className={'profile-width-button'} />
                                                    <Button buttonTitle='Shop' onClick={() => { }} className={'profile-width-button'} />
                                                    <Button buttonTitle='Settings' onClick={() => { }} className={'profile-width-button'} />
                                                    <Button buttonTitle='Me, Myself, and I' onClick={() => { }} className={'profile-width-button'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ProfileScreen