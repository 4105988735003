import React, { useState } from 'react'
import RefreshButton from '../../atoms/RefreshButton/RefreshButton'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { selectGameId } from '../../../redux/Slices/JoinGameSlice';
import { getLobby, removeLobby, selectAllLobby } from '../../../redux/Slices/lobbySlice';
import BackButton from '../../atoms/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';
import Loader from '../../atoms/Loader/Loader';
import { formatPrice } from '../../atoms/FormatPrice';
import { image } from '../../../app/utils/common/image';
import ConfirmModal from '../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../app/utils/interface/confirm';
import { message } from 'antd';

const PlayerRoleScreen = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState();
    const gameId = useSelector(selectGameId);
    const Lobby = useSelector(selectAllLobby);

    // Handle Refresh Button to lobby data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getLobby(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }
    const handleBackButton = () => {
        navigate(-1)
    }

    const handleRemoveBtn = () => {
        if (selectedPlayerId)
            dispatch(removeLobby({ gameId, player_id: selectedPlayerId })).unwrap().then(data => {
                setConfirmModal(false);
                message.success(data.message)
                dispatch(getLobby(gameId))
            })
    }

    const confirmModalProps: confirmModalProps = {
        text: "Do you want to remove this player?",
        confirmModal,
        setConfirmModal,
        buttons: ['Yes', 'No'],
        onPressPositive: handleRemoveBtn,
        onPressNegative: () => { setConfirmModal(false) }
    };

    return (
        <div className='login-container padding-bottom'>
            <BackButton onClick={handleBackButton} className='top-back-btn' />
            <div className='account-heading'>
                <RefreshButton onClick={handleRefreshButton} />
            </div>
            <div className='flex stocks-container'>
                <div className='flex stockContainer' style={{ position: "relative" }}>
                    <div className='timer-heading'>
                        <div className='colorHeading-bckbtn font-bagel portfolio-heading'>Player roles</div>
                    </div>
                    <div className='flex login-upper-container stock-setup-container' style={{ marginTop: "20px" }}>
                        <div className='border-blue'>
                            <div className='border-dark-blue'>
                                <div className='border-orange'>
                                    <div className='border-yellow'>
                                        <div className='border-inner-blue'>
                                            <div className='role-dimension flex'>
                                                {!loading ? <div className='portfolio-showdown-container'>
                                                    {Lobby?.game_players_data?.map((lobby: any, index: number) => {
                                                        return (
                                                            <div className='player-prf-conatiner' key={lobby.id || index}>
                                                                <img src={lobby.image} className='player-profile' draggable="false"
                                                                    onContextMenu={(e) => e.preventDefault()} />
                                                                <div className='player-cross-container'>
                                                                    <div className='text-container'>
                                                                        <div className='text-name'>{lobby.username}</div>
                                                                        <div className='text-subname'>  {lobby.role === 2 ? "Investor" :
                                                                            lobby.role === 1 ? "Broker" : "Broker & Investor"}</div>
                                                                    </div>
                                                                    {/* {lobby.role !== 1 && <div onClick={() => { setConfirmModal(true); setSelectedPlayerId(lobby.player_id) }}><img src={image.CROSSBTN} className='cross-btn' /></div>} */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div> : <Loader />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {confirmModal && <ConfirmModal confirmModalProps={confirmModalProps} />}
        </div >
    )
}

export default PlayerRoleScreen
