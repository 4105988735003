import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface LobbyState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
    player_id?: number | null;
}

// Define the initial state
const initialState: LobbyState = {
    status: 'idle',
    error: null,
    data: null,
    player_id: 0
};

interface RemoveLobbyPayload {
    gameId: number;
    player_id?: number;
}


// getLobby 
export const getLobby = createAsyncThunk('lobby/getLobby', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}/api/game/${gameId}/lobby`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Remove player from the lobby
export const removeLobby = createAsyncThunk('lobby/removeLobby', async ({ gameId, player_id }: RemoveLobbyPayload, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}/api/game/${gameId}/remove-player/${player_id}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Create the lobby slice
const lobbySlices = createSlice({
    name: 'lobby',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLobby.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getLobby.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(getLobby.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(removeLobby.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(removeLobby.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(removeLobby.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })

    }
});

// Selectors for accessing state
export const selectAllLobby = (state: any) => state.lobby?.data;
export const selectLobbyStatus = (state: any) => state.lobby?.status;
export const selectLobbyError = (state: any) => state.lobby?.error;

export default lobbySlices.reducer;