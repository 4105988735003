import React, { FC, useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import LoginPage from '../../pages/LoginPage/LoginPage'
import SplashScreen from '../../components/organisms/SplashScreen/SplashScreen'
import ProfileScreen from '../../components/molecules/ProfileScreen/ProfileScreen'
import AccountDetailsScreen from '../../components/organisms/AccountDetailsScreen/AccountDetailsScreen'
import ShowDownLobby from '../../components/organisms/ShowDownLobby/ShowDownLobby'
import GameSetupScreen from '../../components/organisms/GameSetupScreen/GameSetupScreen'
import FirstPlayerScreen from '../../components/organisms/FirstPlayerScreen/FirstPlayerScreen'
import StockPricesScreen from '../../components/organisms/StockPricesScreen/StockPricesScreen'
import PortfolioScreen from '../../components/organisms/PortfolioScreen/PortfolioScreen'
import TradeScreen from '../../components/organisms/TradeScreen/TradeScreen'
import TransactionScreen from '../../components/organisms/TransactionScreen/TransactionScreen'
import PortfolioShowDown from '../../components/organisms/PortfolioShowDown/PortfolioShowDown'
import PriceUpdatesScreen from '../../components/organisms/PriceUpdatesScreen/PriceUpdatesScreen'
import ShowDownScreen from '../../components/organisms/ShowDownScreen/ShowDownScreen'
import JoinGameScreen from '../../components/organisms/JoinGameScreen/JoinGameScreen'
import SplashScreenEnd from '../../components/molecules/SplashScreenEnd/SplashScreenEnd'
import { AppDispatch } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { getGameSetting, selectTradeDur, updateGameOrderBook } from '../../redux/Slices/GameSettingSlice'
import { message } from 'antd'
import { getAllStock } from '../../redux/Slices/StockSlice'
import { selectGameId } from '../../redux/Slices/JoinGameSlice'
import ChooseAvatars from '../../components/organisms/ChooseAvatars/ChooseAvatars'
import BottomSheet from '../../components/molecules/BottomSheet/BottomSheet'
import MarketMayhemScreen from '../../components/organisms/MarketMayhemScreen/MarketMayhemScreen'
import LeaderboardScreen from '../../components/organisms/LeaderboardScreen/LeaderboardScreen'
import PlayerRoleScreen from '../../components/organisms/PlayerRoleScreen/PlayerRoleScreen'
import LandingScreen from '../../components/organisms/LandingScreen/LandingScreen'
import sendFirebaseEvent from '../firebase/sendFirebaseEvent'
import { selectAccessToken, selectGetUser, selectIsAuth } from '../../redux/Slices/authSlice'
import { getProfile } from '../../redux/Slices/ProfileSlice'
import LogOut from '../../components/atoms/LogOut/LogOut'

const AppRoutes: FC = () => {
    const [loadingTrade, setLoadingTrade] = useState<boolean>(false);
    const [orderBookStatus, setOrderBookStatus] = useState<number>(0);
    const [secondsLeft, setSecondsLeft] = useState<number | null>(null);
    const [helpShow, setHelpShow] = useState<boolean>(false)

    const timerRef = useRef<any>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const gameId = useSelector(selectGameId);
    const tradeDur = useSelector(selectTradeDur);
    const isAuth = useSelector(selectIsAuth);
    const user = useSelector(selectGetUser);
    const accessToken = useSelector(selectAccessToken);
    // Apply the background color for SplashScreen, LoginPage, AccountDetials
    const isSplashOrLogin = location.pathname === '/' || location.pathname === '/login' || location.pathname === '/profile' || location.pathname === '/home' || location.pathname === '/account-details';
    const isSplashLogout = location.pathname === '/landing' || location.pathname === '/showDown';
    const isSplashOrLogout = location.pathname === '/' || location.pathname === '/login' || location.pathname === '/profile' || location.pathname === '/home' || location.pathname === '/account-details' || location.pathname === '/game-setup';


    useEffect(() => {
        const routesRequiringGameSetting = ['/stock-prices', '/portfolio', '/trade', '/transaction'];

        if (routesRequiringGameSetting.includes(location.pathname)) {
            dispatch(getGameSetting(gameId));
        }
    }, [dispatch, gameId, location.pathname]);

    // Navigate to loginscreen after 2.5 second
    useEffect(() => {
        if (location.pathname === '/') {
            const timer = setTimeout(() => {
                if (localStorage.getItem('access_token') || accessToken) {
                    navigate('/profile');
                } else {
                    navigate('/login');
                }
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [location, navigate]);

    useEffect(() => {
        if (!isAuth && !user) {
            if (localStorage.getItem('access_token') || accessToken) {
                dispatch(getProfile())
            } else {
                navigate('/');
            }
        }
    }, [isAuth])

    // Function to handle the trade button
    const handleInitiateTradeButton = () => {
        sendFirebaseEvent('trade_phase_button', 'user_action', 'trade_phase_button', "Trade Phase Button Clicked")
        setLoadingTrade(true);
        setOrderBookStatus(1);
        dispatch(updateGameOrderBook({ gameId, orderbook_status: 1 }))
            .unwrap()
            .then(() => {
                setLoadingTrade(false);
                message.success("Trade initiated.");
                startTimer();
                dispatch(getAllStock(gameId));
            })
            .catch((error) => {
                setLoadingTrade(false);
                console.error('Error initiating trade:', error);
                message.error('Something went wrong.');
            });
    };

    // Function to handle the countdown and stop the trade
    const startTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        setSecondsLeft(tradeDur);
        timerRef.current = setInterval(() => {
            setSecondsLeft((prevSecondsLeft) => {
                if (prevSecondsLeft !== null && prevSecondsLeft > 1) {
                    return prevSecondsLeft - 1;
                } else {
                    clearInterval(timerRef.current);
                    endTrade();
                    return 0;
                }
            });
        }, 1000);
    };
    // Function to end the trade and update orderBookStatus
    const endTrade = () => {
        setOrderBookStatus(0);
        dispatch(updateGameOrderBook({ gameId, orderbook_status: 0 }))
            .unwrap()
            .then(() => {
                message.success("Trade ended.");
                dispatch(getAllStock(gameId));
            })
            .catch((error) => {
                console.error('Error ending trade:', error);
                message.error('Failed to end trade.');
            });
    };
    const isBottomSheet = location.pathname === '/stock-prices' || location.pathname === '/portfolio' || location.pathname === '/trade' || location.pathname === '/transaction' || location.pathname === '/price-updates';

    useEffect(() => {
        const container = document.querySelector('.splash-container');

        const preventContextMenu = (e: Event) => {
            e.preventDefault(); // Prevent the context menu
        };

        // Add the event listener
        container?.addEventListener('contextmenu', preventContextMenu as EventListener);

        // Cleanup on component unmount
        return () => {
            container?.removeEventListener('contextmenu', preventContextMenu as EventListener);
        };
    }, []);

    const handleClose = () => {
        setHelpShow(false)
    }

    return (
        <>
            <div className={isSplashOrLogin ? 'splash-container no-context-menu' : 'stock-bg-container no-context-menu'}>
                {!isSplashOrLogout && <LogOut className={`${isSplashLogout ? '' : 'logout-porfolio'}`} />}
                <Routes>
                    <Route path='/' element={<SplashScreen />} />
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/profile' element={<ProfileScreen />} />
                    <Route path='/account-details' element={<AccountDetailsScreen />} />
                    <Route path='/showDown' element={<ShowDownScreen />} />
                    <Route path='/join-game/:update' element={<JoinGameScreen />} />
                    <Route path='/choose-avatar/:update' element={<ChooseAvatars />} />
                    <Route path='/showdown-lobby' element={<ShowDownLobby />} />
                    <Route path='/game-setup' element={<GameSetupScreen />} />
                    <Route path='/first-player' element={<FirstPlayerScreen />} />
                    <Route path='/stock-prices' element={<StockPricesScreen loadingTrade={loadingTrade} setLoadingTrade={setLoadingTrade} handleInitiateTradeButton={handleInitiateTradeButton} orderBookStatus={orderBookStatus} secondsLeft={secondsLeft} helpShow={helpShow} setHelpShow={setHelpShow} handleClose={handleClose} />} />
                    <Route path='/portfolio' element={<PortfolioScreen secondsLeft={secondsLeft} helpShow={helpShow} setHelpShow={setHelpShow} handleClose={handleClose} />} />
                    <Route path='/trade' element={<TradeScreen secondsLeft={secondsLeft} helpShow={helpShow} setHelpShow={setHelpShow} handleClose={handleClose} />} />
                    <Route path='/transaction' element={<TransactionScreen secondsLeft={secondsLeft} helpShow={helpShow} setHelpShow={setHelpShow} handleClose={handleClose} />} />
                    <Route path='/portfolio-showdown' element={<PortfolioShowDown />} />
                    <Route path='/price-updates' element={<PriceUpdatesScreen />} />
                    <Route path='/market-mayhem' element={<MarketMayhemScreen />} />
                    <Route path='/role' element={<PlayerRoleScreen />} />
                    <Route path='/leaderboard' element={<LeaderboardScreen />} />
                    <Route path='/landing' element={<LandingScreen />} />
                    <Route path='/home' element={<SplashScreenEnd />} />
                </Routes>
                {isBottomSheet ? <BottomSheet /> : ""}
            </div>
        </>
    )
}

export default AppRoutes