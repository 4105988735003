import React, { FC, useState } from 'react';
import { image } from '../../../app/utils/common/image';
import ConfirmModal from '../confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../app/utils/interface/confirm';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { clearToken } from '../../../redux/Slices/authSlice';
import './LogOut.scss'

interface LogOutProps {
    className?: string
}

const LogOut: FC<LogOutProps> = ({ className }) => {
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const dispatch: AppDispatch = useDispatch();

    const handleLogOut = () => {
        dispatch(clearToken());
        setConfirmModal(false)
    };

    const confirmModalProps: confirmModalProps = {
        text: "Do you want to logout?",
        confirmModal,
        setConfirmModal,
        buttons: ['Yes', 'No'],
        onPressPositive: handleLogOut,
        onPressNegative: () => { setConfirmModal(false) }
    };

    return (
        <div className='logout-main_container'>
            <div className={`${className} logout-container`}>
                <img src={image.LOGOUT} className='logout-btn' onClick={() => { setConfirmModal(true) }} />
            </div>
            {confirmModal && <ConfirmModal confirmModalProps={confirmModalProps} />}
        </div>

    )
}

export default LogOut;
