import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { image } from '../../../app/utils/common/image';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getLobby, selectAllLobby } from '../../../redux/Slices/lobbySlice';
import { selectGameId } from '../../../redux/Slices/JoinGameSlice';
import Button from '../../atoms/Button/Button';
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';
import './FirstPlayerScreen.scss';

const FirstPlayerScreen = () => {
    const [angle, setAngle] = useState(0); // Current spinner angle
    const [spinning, setSpinning] = useState(false);
    const [targetAngle, setTargetAngle] = useState(0);
    const [showContinueButton, setShowContinueButton] = useState<boolean>(false);
    const [firstPlayer, setFirstPlayer] = useState<any>(null);
    const gameId = useSelector(selectGameId);
    const Lobby = useSelector(selectAllLobby);

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(getLobby(gameId));
    }, [dispatch]);

    // Function to filter out the brokers (players with role === 1)
    const getValidPlayers = (players: any[]) => {
        // Remove brokers (role === 1)
        return players.filter(player => player.role !== 1);
    };

    const extendedPlayers = getValidPlayers(Lobby?.game_players_data || []);

    const validPlayers = extendedPlayers.filter((player) => !player.isLogo);

    // Filter out only players (no logos) for calculating stop position

    const handleSpin = () => {
        // Divide the wheel into equal segments for each valid player
        const segmentAngle = 360 / validPlayers.length;

        // Randomly select a player as the winner
        const selectedPlayerIndex = Math.floor(Math.random() * validPlayers.length);
        const winningPlayer = validPlayers[selectedPlayerIndex];

        // Calculate the stop angle for the winning segment
        const stopAngle = selectedPlayerIndex * segmentAngle + segmentAngle / 2;

        const fullRotations = 3;
        const newTargetAngle = angle + fullRotations * 340 + stopAngle;

        setTargetAngle(newTargetAngle); // Set the target angle
        setSpinning(true); // Start spinning

        setTimeout(() => {
            setShowContinueButton(true);
            setFirstPlayer(winningPlayer);
            // message.success(
            //     `${winningPlayer.username} shall be the first player to play their Action Card on the board`
            // );
        }, 2000); // Match this to the animation duration
    };

    useEffect(() => {
        if (spinning) {
            const interval = setInterval(() => {
                setAngle((prevAngle) => {
                    if (prevAngle < targetAngle) {
                        return prevAngle + 10; // Increment angle for smooth animation
                    } else {
                        clearInterval(interval); // Stop the interval once the target is reached
                        setSpinning(false); // Stop spinning
                        return targetAngle; // Ensure it stops exactly at the target angle
                    }
                });
            }, 16); // Approximately 60 FPS animation

            return () => clearInterval(interval); // Cleanup interval on component unmount or dependency change
        }
    }, [spinning, targetAngle]);

    // Function to select the spinner image based on the player index or role
    const getSpinnerImage = (index: number) => {
        const spinnerImages = [
            image.SPINNER2,
            image.SPINNER2,
            image.SPINNER3,
            image.SPINNER4,
            image.SPINNER5,
            image.SPINNER6,
        ];
        return spinnerImages[index % spinnerImages.length];
    };
    const selectedSpinnerImage = getSpinnerImage(validPlayers.length - 1);

    const getBoxClass = () => {
        if (selectedSpinnerImage === image.SPINNER2) return `box2_`;
        if (selectedSpinnerImage === image.SPINNER2) return `box2_`;
        if (selectedSpinnerImage === image.SPINNER3) return `box3_`;
        if (selectedSpinnerImage === image.SPINNER4) return `box4_`;
        if (selectedSpinnerImage === image.SPINNER5) return `box5_`;
        return `box-`; // Default box class
    };
    return (
        <div>
            {/* <div className='account-heading'>
                <BackButton onClick={handleBackButton} />
            </div> */}
            <div className='flex login-container' style={{ height: "100vh" }}>
                <div className='main-heading font-bagel' style={{ marginBottom: "25px" }}>First Player</div>
                {validPlayers.length > 1 ?
                    <div className='flex login-upper-container setup-container'>
                        <div className='flex' style={{ flexDirection: "column", position: "relative", overflow: "hidden" }}>
                            <img src={image.SPINTOP} className='spin-top' draggable="false"
                                onContextMenu={(e) => e.preventDefault()} />
                            <div className="spinner-container">
                                <div className={`spinner ${spinning ? 'spinning' : ''}`} style={{ transform: `rotate(${targetAngle}deg)` }}>
                                    {extendedPlayers?.map((player: any, index: number) => (
                                        <div
                                            key={index}
                                            className={`name-box ${getBoxClass()}${index}`}
                                            style={{ backgroundColor: 'transparent' }}
                                        >
                                            {player.username}
                                        </div>
                                    ))}
                                    <img
                                        src={getSpinnerImage(extendedPlayers.length - 1)}
                                        className="spinner-image"
                                        alt="Spinner Background"
                                        draggable="false"
                                        onContextMenu={(e) => e.preventDefault()}
                                    />
                                </div>
                            </div>
                            <img src={image.SPINSTAND} className='spin-stand' />
                        </div>
                        <img
                            src={image.SPINBUTTON}
                            className="spin-button"
                            id="spinButton"
                            alt="Spin Button"
                            onClick={handleSpin}
                            draggable="false"
                            onContextMenu={(e) => e.preventDefault()}
                        />
                        {showContinueButton && <Button buttonTitle='Continue' onClick={() => { navigate('/stock-prices'); sendFirebaseEvent('first_player_button', 'user_action', 'first_player_button', "First Player Continue Button Clicked") }} width={'205px'} />}
                    </div>
                    : <div className='empty-player-text'>Please wait for second player to Join the game</div>}
            </div>
        </div>
    )
}

export default FirstPlayerScreen