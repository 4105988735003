import React, { FC } from 'react'
import { IoRefreshCircleSharp } from 'react-icons/io5';


interface RefreshButtonProps {
    onClick: VoidFunction,
    className?: string
}

const RefreshButton: FC<RefreshButtonProps> = ({ onClick, className }) => {
    return (
        <div className={`${className} refresh-button`}>
            <div onClick={() => { }}>
                <IoRefreshCircleSharp size={40} color='#fff' onClick={onClick} />
            </div>
        </div>
    )
}

export default RefreshButton