import React, { useEffect, useState } from 'react';
import './MarketMayhemScreen.scss'
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';
import { getAllShowDown } from '../../../redux/Slices/ShowDownSlice';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { selectGameId, selectRoleJoinId } from '../../../redux/Slices/JoinGameSlice';
import Button from '../../atoms/Button/Button';
import BackButton from '../../atoms/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import { CompanyMayhem, percentageMayhem } from '../../../app/utils/constants/Options';
import { image } from '../../../app/utils/common/image';
import { getAllStock, selectAllStock, selectMMExist, selectStockError } from '../../../redux/Slices/StockSlice';
import { message, Select } from 'antd';
import { AveragreMarketMayhem, CreateMarketMayhem, getMarketMayhem, RespondMarketMayhem, selectMarketMayhem, selectMarketMayhemError, selectmarketMayhemId, selectMarketMayhemStatus } from '../../../redux/Slices/MarketMayhemSlice';
import Loader from '../../atoms/Loader/Loader';
import { selectRoleId } from '../../../redux/Slices/SelectRoleSlice';
import Input from '../../atoms/Input/Input';
import { IoChevronBackSharp } from 'react-icons/io5';
const { Option } = Select;

const MarketMayhemScreen = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
    const [selectedPercentages, setSelectedPercentages] = useState<number[]>([]);
    const [selectedInvesCompanies, setSelectedInvesCompanies] = useState<any>(null);
    const [selectedInvesPercentages, setSelectedInvesPercentages] = useState<any>(null);
    const [selectedEntries, setSelectedEntries] = useState<any[]>([]);
    const [invesBrokerResponse, setInvesBrokerResponse] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch();
    const gameId = useSelector(selectGameId);
    const navigate = useNavigate()
    const allStocks = useSelector(selectAllStock);
    const marketStatus = useSelector(selectMarketMayhemStatus);
    const marketError = useSelector(selectMarketMayhemError);
    const getMarket = useSelector(selectMarketMayhem)
    const marketMayhemId = useSelector(selectmarketMayhemId);
    const roleId = useSelector(selectRoleId);
    const joinRoleId = useSelector(selectRoleJoinId);
    const roleIdStock = joinRoleId !== undefined ? joinRoleId : roleId;

    useEffect(() => {
        if (marketStatus === 'succeeded') {
            setLoading(false);
        } else if (marketStatus === 'loading') {
            setLoading(true);
        } else if (marketStatus === 'failed') {
            setLoading(false);
            if (marketError?.error?.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [marketStatus]);

    const handleBackButton = () => {
        navigate('/stock-prices')
    }

    useEffect(() => {
        dispatch(getAllStock(gameId));
        dispatch(getMarketMayhem(gameId))
    }, [dispatch]);

    // Handle Refresh Button to reload portfolio value data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getAllShowDown(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }
    const handleSave = () => {
        setLoading(true)
        dispatch(CreateMarketMayhem({ gameId, stockIds: selectedCompanies, percentage: selectedPercentages })).unwrap().then((response) => {
            message.success('Market Mayhem create successfully');
            dispatch(getMarketMayhem(gameId));
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            setSelectedCompanies([])
            setSelectedPercentages([])
            if (error?.error?.message === "The lengths of stock_ids and percentage must be the same.") {
                message.error('Please select both the company and percentage')
            } else {
                message.error(error?.error?.message)
            }
        });
    }

    const handleSaveInves = () => {
        if (selectedEntries.length === 0) {
            message.warning('Please add some selections before submitting.');
            return;
        }
        const stocksId = selectedEntries.map((entry) => ({
            stock_id: entry.company.stock_id,
            percentage: entry.percentage,
        }));

        setLoading(true)

        dispatch(RespondMarketMayhem({ marketMayhemId, stocks: stocksId })).then((response) => {
            setLoading(false);
            message.success("Market mayhem responded successfully.")
            if (roleIdStock === 3) {
                setInvesBrokerResponse(false);
                dispatch(getMarketMayhem(gameId))
            } else {
                navigate('/stock-prices')
            }
        }).catch((error) => {
            setLoading(false);
            setSelectedCompanies([])
            setSelectedPercentages([])
            navigate('/stock-prices')
            message.error(error?.error?.message)
        });
    }

    const onNext = () => {
        if (!selectedInvesCompanies || !selectedInvesPercentages) {
            message.warning('Please select both a company and a percentage.');
            return;
        }

        setSelectedEntries([
            ...selectedEntries,
            {
                company: selectedInvesCompanies,
                percentage: selectedInvesPercentages,
            },
        ]);

        // Clear selections
        setSelectedInvesCompanies(null);
        setSelectedInvesPercentages(null);

        message.success('Selections added. Proceed to the next step.');
    }

    const handleAverage = () => {
        setLoading(true)
        dispatch(AveragreMarketMayhem(marketMayhemId)).unwrap().then((response) => {
            setLoading(false);
            message.success(response.message);
            navigate('/stock-prices')
        }).catch((error) => {
            setLoading(false);
            message.error(error?.error?.message)
        });
    }

    const isNextButtonVisible =
        getMarket?.data?.stock_ids.some(
            (stock: any) => !selectedEntries.some((entry) => entry.company.stock_id === stock.stock_id)
        ) &&
        getMarket?.data.percentage.some(
            (percentage: any) => !selectedEntries.some((entry) => entry.percentage === percentage)
        );
    const investorBroker = roleIdStock === 3 ? invesBrokerResponse : "";

    return (
        <>
            <BackButton onClick={handleBackButton} />
            <div className='account-heading'>
                <RefreshButton onClick={handleRefreshButton} />
            </div>
            <div className='market-mayhem-container'>
                <div className='main-heading font-bagel market-heading'>Market Mayhem </div>
                {roleIdStock !== 2 && roleIdStock !== 3 &&
                    <div className='flex stockContainer' style={{ gap: "20px", marginTop: "20px" }}>
                        <div className='account-border'>
                            <div className='account-container flex market-container'>
                                {!loading ?
                                    <div className='market-dimension flex'>
                                        {marketError || marketError === "Market mayhem history not found, create first." ? (
                                            <>
                                                <div>
                                                    <div className='input-label'>Select Company</div>
                                                    <Select
                                                        value={selectedCompanies}
                                                        onChange={(values: number[]) => setSelectedCompanies(values)}
                                                        style={{ width: '100%' }}
                                                        dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                                                        mode="multiple"
                                                        placeholder='Select company'
                                                        showSearch={false}
                                                    >
                                                        {allStocks?.stocks_data?.map((stock: any) => (
                                                            <Option key={stock.id} value={stock.id} style={{ color: '#1C4384', fontWeight: '700' }}>
                                                                {stock.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>

                                                <div>
                                                    <SelectInput
                                                        label="Select Percentage"
                                                        value={selectedPercentages}
                                                        onChange={(values: any) => setSelectedPercentages(values)}
                                                        options={percentageMayhem.map((stock: any) => ({
                                                            label: stock.label,
                                                            value: stock.value
                                                        }))}
                                                        mode="multiple"
                                                        dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                                                        optionStyle={{ color: '#1C4384', fontWeight: '700' }}
                                                        placeholder='Select Percentage'
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            marketError !== null && (
                                                <div className='market-dimension flex' style={{ padding: "0" }}>
                                                    <div>
                                                        <Input
                                                            label='Companies'
                                                            placeholder='Enter Your Companies'
                                                            type="name"
                                                            id="name"
                                                            name="name"
                                                            value={getMarket?.data?.stock_ids.map((stock: any) => stock.name)}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div>
                                                        <Input
                                                            label='Percentages'
                                                            placeholder='Enter Your Percentage'
                                                            type="name"
                                                            id="name"
                                                            name="name"
                                                            value={getMarket?.data?.percentage.map((percent: any) => `${percent}%`)}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        {(!marketError && marketError !== "Market mayhem history not found, create first.") && <Button onClick={handleAverage} buttonTitle='Average' />}
                                    </div> : <Loader white />}
                            </div>
                        </div>
                        {!loading && (selectedCompanies.length > 0 || selectedPercentages.length > 0) && (marketError === "The lengths of stock_ids and percentage must be the same." || marketError === "Market mayhem history not found, create first.") && <Button onClick={handleSave} buttonTitle={'Save'} width={'225px'} />}
                    </div>}
                {roleIdStock === 2 &&
                    <div className='flex stockContainer' style={{ gap: "20px", marginTop: "20px" }}>
                        <div className='account-border'>
                            <div className='account-container flex market-container'>
                                {!loading ? <div className='market-dimension flex'>
                                    <div className='market_container'>
                                        {selectedEntries.map((entry, index) => (
                                            <div key={index} className="selected-item">
                                                <span>
                                                    {entry.company.name} : {entry.percentage}%
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                    {isNextButtonVisible && <div className='market-dimension flex' style={{ padding: "0" }}>
                                        <div>
                                            <div className="input-label">Select Company</div>
                                            <Select
                                                value={selectedInvesCompanies?.stock_id}
                                                onChange={(value) =>
                                                    setSelectedInvesCompanies(
                                                        getMarket.data.stock_ids.find((stock: any) => stock.stock_id === value)
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                                placeholder="Select company"
                                                showSearch={false}
                                            >
                                                {getMarket.data.stock_ids
                                                    .filter(
                                                        (stock: any) =>
                                                            !selectedEntries.some(
                                                                (entry) => entry.company.stock_id === stock.stock_id
                                                            )
                                                    )
                                                    .map((stock: any) => (
                                                        <Option key={stock.stock_id} value={stock.stock_id} style={{ color: '#1C4384', fontWeight: '700' }}>
                                                            {stock.name}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </div>
                                        <div>
                                            <div className="input-label">Select Percentage</div>
                                            <Select
                                                value={selectedInvesPercentages}
                                                onChange={(value) => setSelectedInvesPercentages(value)}
                                                style={{ width: '100%' }}
                                                placeholder="Select percentage"
                                                dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                                            // optionStyle={{ color: '#1C4384', fontWeight: '700' }}

                                            >
                                                {getMarket.data.percentage
                                                    .filter(
                                                        (percentage: any) =>
                                                            !selectedEntries.some(
                                                                (entry) => entry.percentage === percentage
                                                            )
                                                    )
                                                    .map((percentage: any) => (
                                                        <Option key={percentage} value={percentage} style={{ color: '#1C4384', fontWeight: '700' }}>
                                                            {percentage}%
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>}
                                    {isNextButtonVisible && <Button onClick={onNext} buttonTitle='Next' />}
                                </div>
                                    : <Loader white />}
                            </div>
                        </div>
                        {!isNextButtonVisible && <Button onClick={handleSaveInves} buttonTitle={'Submit'} width={'225px'} />}
                    </div>}
                {roleIdStock === 3 && !investorBroker &&
                    <div className='flex stockContainer' style={{ gap: "20px", marginTop: "20px" }}>
                        <div className='account-border'>
                            <div className='account-container flex market-container'>
                                {!loading ?
                                    <div className='market-dimension flex'>
                                        {marketError || marketError === "Market mayhem history not found, create first." ? (
                                            <>
                                                <div>
                                                    <div className='input-label'>Select Company</div>
                                                    <Select
                                                        value={selectedCompanies}
                                                        onChange={(values: number[]) => setSelectedCompanies(values)}
                                                        style={{ width: '100%' }}
                                                        dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                                                        mode="multiple"
                                                        placeholder='Select company'
                                                        showSearch={false}
                                                    >
                                                        {allStocks?.stocks_data?.map((stock: any) => (
                                                            <Option key={stock.id} value={stock.id} style={{ color: '#1C4384', fontWeight: '700' }}>
                                                                {stock.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>

                                                <div>
                                                    <SelectInput
                                                        label="Select Percentage"
                                                        value={selectedPercentages}
                                                        onChange={(values: any) => setSelectedPercentages(values)}
                                                        options={percentageMayhem.map((stock: any) => ({
                                                            label: stock.label,
                                                            value: stock.value
                                                        }))}
                                                        mode="multiple"
                                                        dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                                                        optionStyle={{ color: '#1C4384', fontWeight: '700' }}
                                                        placeholder='Select Percentage'
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            marketError !== null && (
                                                <div className='market-dimension flex' style={{ padding: "0" }}>
                                                    <div>
                                                        <Input
                                                            label='Companies'
                                                            placeholder='Enter Your Companies'
                                                            type="name"
                                                            id="name"
                                                            name="name"
                                                            value={getMarket?.data?.stock_ids.map((stock: any) => stock.name)}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div>
                                                        <Input
                                                            label='Percentages'
                                                            placeholder='Enter Your Percentage'
                                                            type="name"
                                                            id="name"
                                                            name="name"
                                                            value={getMarket?.data?.percentage.map((percent: any) => `${percent}%`)}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        {roleIdStock === 3 && (!marketError && marketError !== "Market mayhem history not found, create first.") && <Button onClick={() => { setInvesBrokerResponse(true) }} buttonTitle='Your Response' />}
                                        {(!marketError && marketError !== "Market mayhem history not found, create first.") && <Button onClick={handleAverage} buttonTitle='Average' />}
                                    </div> : <Loader white />}
                            </div>
                        </div>
                        {!loading && (selectedCompanies.length > 0 || selectedPercentages.length > 0) && (marketError === "The lengths of stock_ids and percentage must be the same." || marketError === "Market mayhem history not found, create first.") && <Button onClick={handleSave} buttonTitle={'Save'} width={'225px'} />}
                    </div>}
                {roleIdStock === 3 && investorBroker &&
                    <div className='flex stockContainer' style={{ gap: "20px", marginTop: "20px" }}>
                        <div className='account-border'>
                            <div className='account-container flex market-container'>
                                {!loading ? <div className='market-dimension flex'>
                                    {selectedEntries.length >= 1 && <div className='back-btn' onClick={() => { setInvesBrokerResponse(false) }}><IoChevronBackSharp size={18} /></div>}
                                    <div className='market_container'>
                                        {selectedEntries.map((entry, index) => (
                                            <div key={index} className="selected-item">
                                                <span>
                                                    {entry.company.name} : {entry.percentage}%
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                    {isNextButtonVisible && <div className='market-dimension flex' style={{ padding: "0" }}>
                                        {selectedEntries.length < 1 && <div className='back-btn' onClick={() => { setInvesBrokerResponse(false) }}><IoChevronBackSharp size={18} /></div>}
                                        <div>
                                            <div className="input-label">Select Company</div>
                                            <Select
                                                value={selectedInvesCompanies?.stock_id}
                                                onChange={(value) =>
                                                    setSelectedInvesCompanies(
                                                        getMarket.data.stock_ids.find((stock: any) => stock.stock_id === value)
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                                placeholder="Select company"
                                                showSearch={false}
                                            >
                                                {getMarket.data.stock_ids
                                                    .filter(
                                                        (stock: any) =>
                                                            !selectedEntries.some(
                                                                (entry) => entry.company.stock_id === stock.stock_id
                                                            )
                                                    )
                                                    .map((stock: any) => (
                                                        <Option key={stock.stock_id} value={stock.stock_id} style={{ color: '#1C4384', fontWeight: '700' }}>
                                                            {stock.name}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </div>
                                        <div>
                                            <div className="input-label">Select Percentage</div>
                                            <Select
                                                value={selectedInvesPercentages}
                                                onChange={(value) => setSelectedInvesPercentages(value)}
                                                style={{ width: '100%' }}
                                                placeholder="Select percentage"
                                                dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                                            // optionStyle={{ color: '#1C4384', fontWeight: '700' }}

                                            >
                                                {getMarket.data.percentage
                                                    .filter(
                                                        (percentage: any) =>
                                                            !selectedEntries.some(
                                                                (entry) => entry.percentage === percentage
                                                            )
                                                    )
                                                    .map((percentage: any) => (
                                                        <Option key={percentage} value={percentage} style={{ color: '#1C4384', fontWeight: '700' }}>
                                                            {percentage}%
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>}
                                    {isNextButtonVisible && <Button onClick={onNext} buttonTitle='Next' />}
                                </div>
                                    : <Loader white />}
                            </div>
                        </div>
                        {!isNextButtonVisible && <Button onClick={handleSaveInves} buttonTitle={'Submit'} width={'225px'} />}
                    </div>}


            </div >
        </>

    )
}

export default MarketMayhemScreen
