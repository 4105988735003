import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from "firebase/remote-config"
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics, logEvent } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyAv_FOTCWtXQALtQUAmoOGlX07FuclN7gE",
    authDomain: "joyjuncture-172f3.firebaseapp.com",
    projectId: "joyjuncture-172f3",
    storageBucket: "joyjuncture-172f3.firebasestorage.app",
    messagingSenderId: "602159838492",
    appId: "1:602159838492:web:026a9bd6ec4c51ac3976d9"
};

export const app = initializeApp(firebaseConfig);
// export const remoteConfig =
export const messaging = getMessaging(app);
export const remoteConfig = getRemoteConfig(app);

export const analytics = getAnalytics(app);