import React, { FC, useEffect, useState } from 'react';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getPortfolio, selectAllPortfolio, selectPortfolioError, selectPortfolioStatus } from '../../../redux/Slices/PortfolioSlice';
import { selectGameId } from '../../../redux/Slices/JoinGameSlice';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../atoms/FormatPrice';
import { IoMdHelpCircleOutline } from "react-icons/io";
import Loader from '../../atoms/Loader/Loader';
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';
import PortfolioContainer from '../../molecules/PortfolioContainer/PortfolioContainer';
import OutsideClickHandler from 'react-outside-click-handler';
import HelpContainer from '../../molecules/HelpContainer/HelpContainer';
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';
import './PortfolioScreen.scss'

interface PortfolioScreenProps {
    secondsLeft: number | null,
    helpShow: boolean,
    setHelpShow: React.Dispatch<React.SetStateAction<boolean>>,
    handleClose: VoidFunction
}

const PortfolioScreen: FC<PortfolioScreenProps> = ({ secondsLeft, helpShow, setHelpShow, handleClose }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch();
    const portfolio = useSelector(selectAllPortfolio);
    const portfolioStatus = useSelector(selectPortfolioStatus);
    const portfolioError = useSelector(selectPortfolioError);
    const gameId = useSelector(selectGameId)

    useEffect(() => {
        dispatch(getPortfolio(gameId)); // Dispatch Get Profile action
    }, [dispatch])

    // Profile status loading state
    useEffect(() => {
        if (portfolioStatus === 'loading') {
            setLoading(true);
        } else if (portfolioStatus === 'succeeded') {
            setLoading(false);
        } else if (portfolioStatus === 'failed') {
            setLoading(false);
            if (portfolioError?.error?.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [portfolioStatus]);

    const handleBackButton = () => {
        navigate(-1)
    }

    // Handle Refresh Button to reload portfolio data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getPortfolio(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    return (
        <div className='padding-bottom'>
            <div className='account-heading'>
                {/* <BackButton onClick={handleBackButton} buttonHeading='Portfolio' className='colorHeading-bckbtn font-bagel' /> */}
                <RefreshButton onClick={handleRefreshButton} />
            </div>
            <div className='timer-heading'>
                <div className='colorHeading-bckbtn font-bagel portfolio-heading portfolio-text'>Portfolio </div>
                {secondsLeft && secondsLeft > 0 && <div className='seconds-left'>{secondsLeft} Sec</div>}
            </div>
            <div className='flex stocks-container'>
                <div className='flex stockContainer' style={{ gap: "20px", position: "relative" }}>
                    <div className='help-btn help-trade-btn' onClick={() => { sendFirebaseEvent('help_button', 'user_action', 'help_button', "Help Button Clicked"); setHelpShow(!helpShow); }}>
                        <IoMdHelpCircleOutline color='#fff' size={28} cursor={'pointer'} />
                    </div>
                    {helpShow && <OutsideClickHandler onOutsideClick={handleClose}>
                        <HelpContainer setHelpShow={setHelpShow} />
                    </OutsideClickHandler>}
                    <div className='net-worth'>
                        <div className='player-winner net_worth_text font-bagel'>Net worth: <span className='net_worth_amount'>{formatPrice(portfolio?.total_balance)}</span></div>
                        <div className='portfolio-total-bg'>
                            <div className='total-pl'>
                                <div className='total-pl-container'>
                                    <div className='total-text'>P&L</div>
                                    {!loading && <div className={`${portfolio?.total_profit_loss.startsWith('-') ? 'portfolio-red-total' : 'portfolio-total'}`}>{formatPrice(portfolio?.total_profit_loss)}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='account-border'>
                        <div className='account-container flex portfolio-container'>
                            {!loading ? <PortfolioContainer portfolio={portfolio} /> : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PortfolioScreen