import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface SelectRoleState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
    role: number | null;
    avatar_id?: number | null;
    player_id?: number | null;
}

interface SelectRolePayload {
    role: number;
    gameId: number;
    avatar_id?: number
}

interface UpdateRolePayload {
    role: number;
    gameId: number;
    avatar_id?: number;
    player_id?: number;
}

// Define the initial state
const initialState: SelectRoleState = {
    status: 'idle',
    error: null,
    data: null,
    role: null,
    avatar_id: 0,
    player_id: 0
};

// Async thunk for SelectRole
export const SelectRole = createAsyncThunk(
    'role/SelectRole',
    async ({ role, gameId, avatar_id }: SelectRolePayload, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${BASE_URL}/api/game/${gameId}/select-role`, { role, avatar_id });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Async thunk for UpdateRole
export const UpdateRole = createAsyncThunk(
    'role/UpdateRole',
    async ({ role, gameId, avatar_id, player_id }: UpdateRolePayload, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${BASE_URL}/api/game/${gameId}/change-role`, { role, avatar_id, player_id });
            console.log('response', response)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Create the JoinGame slice
const SelectRoleSlices = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setRole(state,action:PayloadAction<{role:number}>){
            state.role=action.payload.role;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(SelectRole.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(SelectRole.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                state.role = action.payload.data.role;
                state.avatar_id = action.payload.data.avatar_id;
                state.error = null;
            })
            .addCase(SelectRole.rejected, (state, action) => {
                state.status = 'failed';
                const errorPayload = action.payload as any;
                state.error = errorPayload?.message || errorPayload?.error?.message || 'Failed to select role';
            })
            .addCase(UpdateRole.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(UpdateRole.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                console.log('action.payload', action.payload)
                state.error = null;
            })
            .addCase(UpdateRole.rejected, (state, action) => {
                state.status = 'failed';
                const errorPayload = action.payload as any;
                state.error = errorPayload?.message || errorPayload?.error?.message || 'Failed to update role';
            });
    }
});
export const selectRoleStatus = (state: any) => state.role.status;
export const selectRoleError = (state: any) => state.role?.error;
export const selectRoleId = (state: any) => state.role?.role;
export const selectAvatarId = (state: any) => state.role?.avatar_id;
export const selectPlayerId = (state: any) => state.role?.player_id;

export const { setRole } = SelectRoleSlices.actions

export default SelectRoleSlices.reducer;