import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface JoinGameState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
    game_id: number | null;
    roleId: number | null;
    broker_exists: boolean
}

interface JoinGamePayload {
    code: string;
}

interface SelectRolePayload {
    role: number;
    gameId: number;
    roleId: number
}

// Define the initial state
const initialState: JoinGameState = {
    status: 'idle',
    error: null,
    data: null,
    game_id: null,
    roleId: null,
    broker_exists: false
};

// Async thunk for JoinGame
export const JoinGame = createAsyncThunk(
    'game/JoinGame',
    async ({ code }: JoinGamePayload, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${BASE_URL}${ApiConstants.JOINGAME}`, {
                code: code
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);


// Create the JoinGame slice
const JoinGameSlices = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setJoinRole(state, action: PayloadAction<{ roleId: number }>) {
            state.roleId = action.payload.roleId;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(JoinGame.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(JoinGame.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.game_id = action.payload.data.game_id;
                state.broker_exists = action.payload.data.broker_exists;
                state.roleId = action.payload.data?.role
                state.error = null;
            })
            .addCase(JoinGame.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});
export const selectJoinGameStatus = (state: any) => state.game?.status;
export const selectJoinGameError = (state: any) => state.game?.error;
export const selectGameId = (state: any) => state.game?.game_id;
export const selectBrokerExists = (state: any) => state.game?.broker_exists;
export const selectRoleJoinId = (state: any) => state.game?.roleId;

export const { setJoinRole } = JoinGameSlices.actions

export default JoinGameSlices.reducer;