import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import BackButton from '../../atoms/BackButton/BackButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStock, selectStockError, selectStockStatus, updateStock } from '../../../redux/Slices/StockSlice';
import { selectGameId } from '../../../redux/Slices/JoinGameSlice';
import Loader from '../../atoms/Loader/Loader';
import KeypadButton from '../../atoms/KeypadButton/KeypadButton';
import './PriceUpdatesScreen.scss';
import { formatPrice } from '../../atoms/FormatPrice';
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';

const PriceUpdatesScreen = () => {
    const location = useLocation();
    const { stock } = location.state || {};

    const [price, setPrice] = useState<number>(Number(stock?.current_price));
    const [companyImage, setCompanyImage] = useState<string>(stock?.name);
    const [companyProfilImage, setCompanyProfileImage] = useState<string>(stock?.image);
    const [adjustment, setAdjustment] = useState<any>();
    const [operation, setOperation] = useState<string>('');
    const [inputDisplay, setInputDisplay] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const gameId = useSelector(selectGameId);
    const stockStatus = useSelector(selectStockStatus)
    const stockError = useSelector(selectStockError);

    // const handleNumberPress = (num: number) => {
    //     setAdjustment(num);
    //     setInputDisplay((prev) => prev + num.toString());
    // };

    const handleNumberPress = (num: number) => {
        setAdjustment((prevAdjustment: any) => {
            return prevAdjustment !== undefined ? prevAdjustment + num.toString() : num.toString();
        });
        setInputDisplay((prev) => prev + num.toString());
    };

    useEffect(() => {
        if (stockStatus === 'loading') {
            setLoading(true);
        } else if (stockStatus === 'succeeded') {
            setLoading(false);
        } else if (stockStatus === 'failed') {
            setLoading(false);
            if (stockError?.error?.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [stockStatus]);

    useEffect(() => {
        dispatch(getAllStock(gameId));
    }, [dispatch]);

    // Handle operations and prevent displaying adjustment when it is 0
    const handleOperationPress = async (operationType: string) => {
        switch (operationType) {
            case '+':
            case '-':
                setOperation(operationType);
                setInputDisplay((adjustment && adjustment !== '') ? `${operationType}${adjustment}` : `${operationType}`);
                break;
            case 'OK':
                sendFirebaseEvent('price_update_button', 'user_action', 'price_update_button', "Price Update OK Button Clicked")
                if (adjustment > 0 || adjustment !== '') {
                    setLoading(true);
                    navigate('/stock-prices')
                    try {
                        const response = await dispatch(updateStock({
                            stock_id: stock.id,
                            operation: operation,
                            value: Number(adjustment),
                            gameId: gameId
                        })).unwrap();
                        message.success(response.message);
                        navigate('/stock-prices');
                        dispatch(getAllStock(gameId))
                        setLoading(false);
                    } catch (error) {
                        message.error('Please add operation');
                        setLoading(false);
                        console.error("Failed to update stock:", error);
                    }
                }
                break;
            case 'Undo':
                setAdjustment('');
                setInputDisplay('');
                break;
            default:
                break;
        }
    };

    const handleBackButton = () => {
        navigate('/stock-prices')
    }

    // Handle decimal input
    const handleDecimalPress = () => {
        if (!inputDisplay.includes('.')) {
            setAdjustment((prev: any) => prev === '' ? '0.' : prev + '.');
            setInputDisplay((prev) => prev + '.');
        }
    };

    return (
        <div className='padding-bottom'>
            <div className='account-heading'>
                <BackButton onClick={handleBackButton} buttonHeading='Price Updates' className='colorHeading-backbtn font-bagel' />
            </div>
            <div className='flex stocks-container' style={{ height: window.innerWidth > 750 ? "100vh" : "auto" }}>
                <div className='flex login-upper-container stock-setup-container' style={{ paddingTop: window.innerWidth < 750 ? "6.5rem" : "0rem", height: '38%', paddingBottom: '35px' }}>
                    <div className='border-blue'>
                        <div className='border-dark-blue'>
                            <div className='border-orange'>
                                <div className='border-yellow'>
                                    <div className='border-inner-blue'>
                                        <div className='updates-dimension flex'>
                                            {!loading ? <div className='priceMainContainer'>
                                                <div className="priceContainer">
                                                    <img
                                                        src={companyProfilImage}
                                                        className='company-price-logo'
                                                        alt={`${companyImage} logo`}
                                                        draggable="false"
                                                        onContextMenu={(e) => e.preventDefault()}
                                                    />
                                                    <div className='price-vi'>
                                                        <div>Current Price</div>
                                                        <span className="priceValue">{formatPrice(price)}</span>
                                                    </div>
                                                </div>

                                                <input
                                                    className='search-input price-input'
                                                    placeholder='50'
                                                    type="text"
                                                    id="stockPrice"
                                                    name="stockPrice"
                                                    value={`${inputDisplay || ''}%`}
                                                    readOnly
                                                    onWheel={(e: any) => e.target.blur()}
                                                />
                                            </div> : <Loader />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='keypad-container'>
                    <div className="keypad">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                            <KeypadButton key={num} label={num.toString()} onPress={() => handleNumberPress(num)} />
                        ))}
                        <div className="centered-buttons">
                            <KeypadButton label="10" onPress={() => handleNumberPress(10)} />
                            <KeypadButton label="0" onPress={() => handleNumberPress(0)} />
                        </div>
                        {/* <KeypadButton label="." onPress={handleDecimalPress} /> */}
                    </div>
                    <div className='keypad-symbol'>
                        <KeypadButton label="Undo" onPress={() => handleOperationPress('Undo')} isOperator style={{ fontSize: "15px" }} />
                        <KeypadButton label="+" onPress={() => handleOperationPress('+')} isOperator />
                        <KeypadButton label="-" onPress={() => handleOperationPress('-')} isOperator />
                        <KeypadButton label="OK" onPress={() => handleOperationPress('OK')} isOperator />
                    </div>
                </div>
                {/* <BottomSheet /> */}
            </div>
        </div>
    );
}

export default PriceUpdatesScreen;