import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getLobby, selectAllLobby, selectLobbyError, selectLobbyStatus } from '../../../redux/Slices/lobbySlice';
import { selectGameId, selectRoleJoinId } from '../../../redux/Slices/JoinGameSlice';
import { selectRoleId } from '../../../redux/Slices/SelectRoleSlice';
import { startGame } from '../../../redux/Slices/StartGame';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import Button from '../../atoms/Button/Button';
import Loader from '../../atoms/Loader/Loader';
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';
import './ShowDownLobby.scss';

const ShowDownLobby = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch();
    const Lobby = useSelector(selectAllLobby);
    const lobbyStatus = useSelector(selectLobbyStatus);
    const lobbyError = useSelector(selectLobbyError);
    const gameId = useSelector(selectGameId);
    const roleId = useSelector(selectRoleId);
    const joinRoleId = useSelector(selectRoleJoinId);

    useEffect(() => {
        dispatch(getLobby(gameId));
    }, [dispatch]);

    const shouldShowButtons = () => {

        const role = joinRoleId !== undefined ? joinRoleId : roleId;
        return role !== 2;
    };

    useEffect(() => {
        if (lobbyStatus === 'succeeded') {
            if ((Lobby?.game_status === 2 || Lobby?.game_status === 3)) {
                navigate('/stock-prices');
            }
        }
    }, [lobbyStatus, Lobby?.game_status, navigate]);

    // Lobby status loading state
    useEffect(() => {
        if (lobbyStatus === 'loading') {
            setLoading(true);
        } else if (lobbyStatus === 'succeeded') {
            setLoading(false);
        } else if (lobbyStatus === 'failed') {
            message.error(lobbyError?.error?.message);
            setLoading(false);
            if (lobbyError?.error?.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [lobbyStatus]);

    const handleBackButton = () => {
        navigate(-1);
        navigate('/showdown-lobby')
    }

    // Handle Refresh Button to reload lobby data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getLobby(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const handleStartGameButton = () => {
        sendFirebaseEvent('start_game_button', 'user_action', 'start_game_button', "Start Game Button Clicked")
        setLoading(true)
        dispatch(startGame(gameId)).unwrap().then((response) => {
            setLoading(false)
            message.success('Game started successfully');
            navigate('/first-player')
        }).catch((error) => {
            setLoading(false)
            message.error(error?.error?.message)
        });
    };

    const handleUpdateRole = () => {
        if (Lobby && Lobby.game_players_data.length === 7) {
            navigate('/choose-avatar/true')
        } else {
            navigate('/join-game/true');
        }
        sendFirebaseEvent('update_role_button', 'user_action', 'update_role_button', "Update Role Button Clicked");
    };

    const showUpdateRoleBtn = () => {
        let updateRole: any;
        if (Lobby && Lobby.game_players_data.length === 7) {
            return updateRole;
        }
        return updateRole
    }

    console.log('Lobby', Lobby)
    return (
        <div>
            <div className='account-heading'>
                {/* <BackButton onClick={handleBackButton} /> */}
                <RefreshButton onClick={handleRefreshButton} />
            </div>
            <div className='flex login-container join-upper' style={{ justifyContent: "start" }}>
                <div className='main-heading font-bagel' style={{ marginTop: "5px" }}>Showdown <br /> Lobby</div>
                <div className='flex login-upper-container '>
                    <div className='border-blue'>
                        <div className='border-dark-blue'>
                            <div className='border-orange'>
                                <div className='border-yellow'>
                                    <div className='border-inner-blue'>
                                        <div className='lobby-dimension flex'>
                                            <div className='lobby-label'>Waiting for other players to join...
                                                <span className="get-help-addOn-hover" >
                                                    <span className="help-image-logo" > <IoMdInformationCircleOutline size={20} cursor='pointer' style={{ marginBottom: "-5px" }} /></span>
                                                    <span className="get-help-lobby-hover">Share the board code with friends. Players will appear as they join. Keep your board ready </span>
                                                </span></div>

                                            {!loading ? Lobby?.length !== 0 ? <div className='player-container'>
                                                {Lobby?.game_players_data?.map((lobby: any, index: number) => {
                                                    return (
                                                        <div className='player-prf-conatiner' key={lobby.id || index}>
                                                            <img src={lobby.image} className='player-profile' draggable="false"
                                                                onContextMenu={(e) => e.preventDefault()} />
                                                            <div className='text-container'>
                                                                <div className='text-name'>{lobby.username}</div>
                                                                <div className='text-subname'>  {lobby.role === 2 ? "I'm Investor" :
                                                                    lobby.role === 1 ? "I'm Broker" : "I'm Broker & Investor"}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div> : <div>no</div> : <div className="flex items-center justify-center">
                                                <Loader />
                                            </div>}
                                            <div className='lobby-btn-main_container'>
                                                {shouldShowButtons() && (
                                                    <div className='lobby-btn-container'>
                                                        <Button
                                                            onClick={() => { navigate('/game-setup'); sendFirebaseEvent('setup_game_button', 'user_action', 'setup_game_button', "Setup Game Button Clicked") }}
                                                            buttonTitle='Setup Game'
                                                            className={'start-button'}
                                                        />
                                                        <Button
                                                            onClick={handleStartGameButton}
                                                            buttonTitle='Start Game'
                                                            className={'start-button'}
                                                        />
                                                    </div>
                                                )}
                                                <Button
                                                    onClick={handleUpdateRole}
                                                    buttonTitle='Update Role'
                                                    className={'start-button'}
                                                />
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowDownLobby