import React, { FC, useState } from 'react'
import Button from '../../atoms/Button/Button'
import { formatPrice } from '../../atoms/FormatPrice';
import { updateTrade } from '../../../redux/Slices/TradeSlice';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { getPortfolio } from '../../../redux/Slices/PortfolioSlice';
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';

interface TradeBuyContainerProps {
    port: any,
    gameId: number,
    tradeType: any,
    setSelectedStock: React.Dispatch<React.SetStateAction<any>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

const TradeBuyContainer: FC<TradeBuyContainerProps> = (props) => {
    const { port, gameId, tradeType, setSelectedStock, setLoading } = props;
    const [quantity, setQuantity] = useState(tradeType === 1 ? port?.total_quantity || '' : '');
    const dispatch: AppDispatch = useDispatch();

    const handleOrderButton = async () => {
        sendFirebaseEvent('order_trade_button', 'user_action', 'order_trade_button', 'Order Trade Button Clicked')
        setLoading(true)
        try {
            const response = await dispatch(updateTrade({
                stock_id: port.stock_id,
                quantity: quantity,
                trade_type: tradeType, // 0 - buy, 1 - sell
                gameId: gameId
            })).unwrap();
            setSelectedStock(null);
            await dispatch(getPortfolio(gameId))
            setLoading(false)
        } catch (error: any) {
            message.error(error?.error?.message)
            setSelectedStock(null);
        }
        setLoading(false)
    }
    // Handle quantity input change
    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^\d.]/g, '');
        if (!isNaN(Number(numericValue))) {
            setQuantity(Number(numericValue));
        }
    };

    // Calculate total price
    const calculateTotal = () => {
        const currentPrice = parseFloat(port.current_price);
        const total = quantity * currentPrice;

        return formatPrice(total);
    };

    return (
        <div className='trade-buy-container'>
            <div >
                <div className='trade-flex'>
                    <div>
                        <div className='trade-label'>Quantity</div>
                        <input
                            className='search-input trade-input'
                            placeholder='Enter Quantity'
                            type="string"
                            id="quantity"
                            name="quantity"
                            inputMode='numeric'
                            value={quantity === 0 ? '' : formatPrice(quantity)}
                            onChange={handleQuantityChange}
                            onWheel={(e: any) => e.target.blur()}
                        />
                    </div>
                    <div>
                        <div className='trade-label'>Price</div>
                        <input
                            className='search-input trade-input'
                            placeholder='-'
                            type="any"
                            id="price"
                            name="price"
                            value={`${formatPrice(port?.current_price) || ''}`}
                            readOnly
                            onWheel={(e: any) => e.target.blur()}
                        />
                    </div>
                </div>

            </div>
            <div className='trade-total'>
                <div className='trade-text-total'>Total:</div>
                <div className='trade-price-total'>{calculateTotal()}</div>
            </div>
            <Button buttonTitle='Order' onClick={handleOrderButton} width={'205px'} />
        </div>
    )
}

export default TradeBuyContainer