import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectGameId } from '../../../redux/Slices/JoinGameSlice';
import { AppDispatch } from '../../../redux/store';
import { formatPrice } from '../../atoms/FormatPrice';
import Loader from '../../atoms/Loader/Loader';
import { endGame } from '../../../redux/Slices/StartGame';
import Button from '../../atoms/Button/Button';
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';
import './PortfolioShowDown.scss';
import { getAllShowDown, selectAllShowDown, selectShowDownError, selectShowDownStatus } from '../../../redux/Slices/ShowDownSlice';
import { confirmModalProps } from '../../../app/utils/interface/confirm';
import ConfirmModal from '../../atoms/confirmation-modal/confirmation-modal';

const PortfolioShowDown = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);

    const Lobby = useSelector(selectAllShowDown);
    const lobbyStatus = useSelector(selectShowDownStatus);
    const lobbyError = useSelector(selectShowDownError)
    const gameId = useSelector(selectGameId);
    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (gameId) {
            dispatch(getAllShowDown(gameId)); // Fetch stock prices 
        }
    }, [dispatch, gameId]);

    // Lobby status loading state
    useEffect(() => {
        if (lobbyStatus === 'loading') {
            setLoading(true);
        } else if (lobbyStatus === 'succeeded') {
            setLoading(false);
        } else if (lobbyStatus === 'failed') {
            setLoading(false);
            if (lobbyError?.error?.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [lobbyStatus]);

    const handleBackButton = () => {
        navigate(-1)
    }

    const handleEndGameButton = () => {
        setLoading(true)
        dispatch(endGame(gameId)).unwrap().then((response) => {
            setLoading(false)
            message.success('Game Ended successfully');
            navigate('/leaderboard')
        }).catch((error) => {
            setLoading(false)
            message.error(error?.message)
        });
    };

    // Handle Refresh Button to reload portfolio value data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getAllShowDown(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const confirmModalProps: confirmModalProps = {
        text: "Do you really want to end the game?",
        confirmModal,
        setConfirmModal,
        buttons: ['Yes', 'No'],
        onPressPositive: handleEndGameButton,
        onPressNegative: () => { setConfirmModal(false) }
    };


    return (
        <div className='login-container flex end-game-container' style={{ height: "100vh" }}>
            <div className='account-heading'>
                {/* <BackButton onClick={handleBackButton} /> */}
                <RefreshButton onClick={handleRefreshButton} />
            </div>
            <div className='flex stocks-container'>
                <div className='flex portContainer'>
                    <div className='main-heading font-bagel' >Showdown</div>
                    <div className='flex login-upper-container stock-setup-container'>
                        <div className='border-blue'>
                            <div className='border-dark-blue'>
                                <div className='border-orange'>
                                    <div className='border-yellow'>
                                        <div className='border-inner-blue'>
                                            <div className='portfolio-dimension flex'>
                                                {!loading ? <div className='portfolio-showdown-container'>
                                                    {Lobby?.map((value: any, index: number) => {
                                                        return (
                                                            <div className='player-prf-conatiner'>
                                                                {/* <div className='player-profile-bg'><div className='player-profile-text'>{index + 1}</div></div> */}
                                                                <img src={value.image} className='player-profile' />
                                                                <div className='text-container'>
                                                                    <div className='text-name'>{formatPrice(value.balance)}</div>
                                                                    <div className='text-subname'>{value.username}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div> : <Loader />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex' style={{ gap: "20px" }}>
                            <Button onClick={handleBackButton} buttonTitle='Resume Game' className={'Portfolio-btn'} />
                            <Button onClick={() => setConfirmModal(true)} buttonTitle='End Game' className={'Portfolio-btn'} />
                        </div>

                    </div>
                </div>
                {confirmModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

            </div>
        </div>
    )
}

export default PortfolioShowDown