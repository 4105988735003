import React, { useState } from 'react'
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import Loader from '../../atoms/Loader/Loader';
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getAllShowDown, selectAllShowDown } from '../../../redux/Slices/ShowDownSlice';
import { selectGameId } from '../../../redux/Slices/JoinGameSlice';
import { formatPrice } from '../../atoms/FormatPrice';
import './LeaderboardScreen.scss'
import { image } from '../../../app/utils/common/image';
import sendFirebaseEvent from '../../../app/firebase/sendFirebaseEvent';

const LeaderboardScreen = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate()

    const gameId = useSelector(selectGameId);
    const Lobby = useSelector(selectAllShowDown);

    const shareContent = {
        title: "Leaderboard",
        text: "Check out the leaderboard!",
        url: "https://joyjuncture.com/leaderboard" // Replace with your leaderboard URL
    };

    const handleShare = () => {
        if (navigator.share) {
            // Use Web Share API if supported (for mobile browsers)
            navigator.share(shareContent)
                .then(() => console.log("Content shared successfully"))
                .catch((error) => console.error("Error sharing content", error));
        } else {
            // Fallback: Open WhatsApp share URL and show Instagram alert
            shareViaWhatsApp();
        }
    };

    const shareViaWhatsApp = () => {
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
            `${shareContent.text} ${shareContent.url}`
        )}`;
        window.open(whatsappUrl, "_blank");
    };

    const shareViaInstagram = () => {
        // Instagram sharing must be handled via the app or Instagram's deep linking.
        alert("Instagram sharing must be handled via their app or deep linking.");
    };

    // Handle Refresh Button to reload portfolio value data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getAllShowDown(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const getOrdinalSuffix = (rank: number) => {
        if (rank >= 10 && rank <= 20) {
            return `${rank}th`;
        }
        switch (rank % 10) {
            case 1:
                return `${rank}st`;
            case 2:
                return `${rank}nd`;
            case 3:
                return `${rank}rd`;
            default:
                return `${rank}th`;
        }
    };

    // Generate rankings with same rank for players with equal balance
    const sortedLobby = [...Lobby].sort((a, b) => b.balance - a.balance);
    let currentRank = 1;
    let lastBalance: any = null;

    const rankedLobby = sortedLobby.map((player, index) => {
        if (player.balance !== lastBalance) {
            currentRank = index + 1; // Update rank only when balance changes
        }
        lastBalance = player.balance;
        return { ...player, rank: currentRank };
    });

    return (
        <div>
            <div className='login-containers' >
                {/* <BackButton onClick={handleBackButton} className='top-back-btn' /> */}
                <div className='account-heading'>
                    <RefreshButton onClick={handleRefreshButton} />
                </div>
                <div className='flex stocks-container'>
                    <div className='flex stockContainer' style={{ position: "relative" }}>
                        <div className='timer-heading'>
                            <div className='colorHeading-bckbtn font-bagel portfolio-heading' style={{ fontSize: "53px" }}>Leaderboard</div>

                        </div>
                        <div className='flex login-upper-container stock-setup-container' style={{ marginTop: "20px" }}>
                            <div className='leader-dimension flex'>
                                {!loading ? (
                                    <div className='portfolio-showdown-container' style={{ paddingRight: "0px" }}>
                                        {rankedLobby.map((value, index) => (
                                            <div key={index} className='leader-prf-conatiner'>
                                                <img src={value.image} className='leader-profile' />
                                                <div className='leader-container'>
                                                    <div className='text-container' style={{ lineHeight: "23px" }}>
                                                        <div className='text-name'>{formatPrice(value.balance)}</div>
                                                        <div className='text-subname-leader'>{value.username}</div>
                                                    </div>
                                                    <div className='player-winner-flex'>
                                                    <div className='player-profile-bg'>
                                                        <div className='player-profile-text'>{getOrdinalSuffix(value.rank)}</div>
                                                    </div>
                                                    <div className='player-winner'>{value.rank_name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <Loader />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='leader-btns' style={{ paddingTop: "20px" }}>
                    <div className='stock-prices-btns'>
                        <Button buttonTitle='Restart the Game' onClick={() => { navigate('/landing'); sendFirebaseEvent('restart_game_button', 'user_action', 'restart_game_button', 'Restart Game Button Clicked') }} className={'leader-width-button'} />
                        <Button buttonTitle='Buy JJ Products' onClick={() => { window.open('https://joyjuncture.com',"__blank"); sendFirebaseEvent('shop_now_button', 'user_action', 'shop_now', 'Play Showdown Button Clicked') }} className={'leader-width-button'} />
                    </div>
                    <Button buttonTitle='Home' onClick={() => { navigate('/profile'); sendFirebaseEvent('home_button', 'user_action', 'home_button', 'Home Button Clicked') }} className={'leader-width-button'} />
                </div>

                <div className='flex stockContainer' style={{ gap: "20px", margin: "25px 0px" }}>
                    <div className='account-border'>
                        <div className='account-container flex leader-social-container'>
                            <div className='social-text'>Follow Joy Juncture on Social Media</div>
                            <div className='social-logos'>
                                <a href='https://www.instagram.com/joy_juncture' target='_blank' className='social-logo-container'>
                                    <img src={image.INSTAGRAM} className='social-logo' />
                                </a>
                                <a href='https://www.linkedin.com/company/joy-juncture' target='_blank' className='social-logo-container'>
                                    <img src={image.LINKEDIN} className='social-logo' />
                                </a>
                                <a href='https://x.com/Joy_Juncture' target='_blank' className='social-logo-container'>
                                    <img src={image.X} className='social-logo' />
                                </a>
                                <a href='https://discord.gg/M4c8S6UXVp' target='_blank' className='social-logo-container'>
                                    <img src={image.DISCORD} className='social-logo' />
                                </a>
                                <a href='https://www.youtube.com/@Joy_Juncture' target='_blank' className='social-logo-container'>
                                    <img src={image.YOUTUBE} className='social-logo' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    );
};

export default LeaderboardScreen;