import React, { FC } from 'react'
import { formatPrice } from '../../atoms/FormatPrice';
import './PortfolioContainer.scss';

interface PortfolioContainerProps {
    portfolio: any
}

const PortfolioContainer: FC<PortfolioContainerProps> = (props) => {
    const { portfolio } = props;

    return (
        <div className='prices-container por-prices-container'>
            {portfolio?.portfolio_data?.map((folio: any) => {
                return (
                    <div className={`${folio.total_quantity === 0 ? 'player-prf-conatiner player-prf-conatiner-bg' : 'player-prf-conatiner'} portfolio-height`}>
                        <img src={folio?.image} className='company-profile' draggable="false"
                            onContextMenu={(e) => e.preventDefault()} />
                        <div className='pricetext-container' style={{ width: "100%" }}>
                            <div className='text-container'>
                                <div className='text-name'>{folio.name}</div>
                                <div className='text-subname'>Qty: {folio.total_quantity === 0 ? '0' : formatPrice(folio.total_quantity)} | Inv: {folio.total_invested_price === 0 ? '0' : formatPrice(folio.total_invested_price)}</div>
                            </div>
                            <div className='text-container' style={{ textAlign: "right" }}>
                                <div className={`${folio?.profit_loss < 0 ? 'text-name-red' : folio?.profit_loss > 0 ? 'text-name-green' : 'text-name'}`}>
                                    {formatPrice(folio.profit_loss === 0 ? '0' : folio.profit_loss)}
                                </div>
                                <div className='text-subname'>CP: {formatPrice(folio.current_price)}</div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default PortfolioContainer