import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { apiClient } from "../../app/helper/axiosHelper";
import { setUser } from "./authSlice";

// Define types for your state and API responses
interface ProfileState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
}

// Define the initial state
const initialState: ProfileState = {
    status: 'idle',
    error: null,
    data: null,
};

const getStore = () => {
    const { store } = require("../store");
    return store;
};


// getProfile 
export const getProfile = createAsyncThunk('profile/getProfile', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETPROFILE}`);
        let store = getStore();
        store.dispatch(setUser({ user: response.data.data, isAuthUser: true }))
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Create the profile slice
const profileSlices = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setUserProfile(state, action: PayloadAction<any>) {
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAllProfile = (state: any) => state.profile.data;
export const selectProfileStatus = (state: any) => state.profile?.status;
export const selectProfileError = (state: any) => state.profile?.error;

export const { setUserProfile } = profileSlices.actions

export default profileSlices.reducer;